import React from 'react';

const RefundPolicy = () => {
  return (
    <div className="max-w-2xl mx-auto mt-8 font-primary">
      <h1 className="text-3xl font-bold text-primary mb-4">Refund Policy</h1>
      
      <p className="text-accent mb-4">
        Thank you for choosing Trading Beacon. We appreciate your trust in our products and services. Please read our Refund Policy carefully before making any purchases.
      </p>

      <h2 className="text-xl font-bold text-primary mb-2">1. No Refunds:</h2>
      <p className="text-accent mb-4">
        Once a product is purchased from Trading Beacon, it is considered that you have accepted all the terms and conditions outlined in our policies. As our courses and services are delivered immediately upon purchase, we do not offer any refunds under any circumstances.
      </p>

      <h2 className="text-xl font-bold text-primary mb-2">2. Acceptance of Terms:</h2>
      <p className="text-accent mb-4">
        By purchasing a product or service from Trading Beacon, you acknowledge and agree that you have read, understood, and accepted all terms and conditions. This includes any rules, regulations, and guidelines associated with the course or service.
      </p>

      <h2 className="text-xl font-bold text-primary mb-2">3. Immediate Access:</h2>
      <p className="text-accent mb-4">
        Our courses and services are designed to provide immediate value upon purchase. Therefore, once the product is accessed or the service is utilized, no refunds will be provided.
      </p>

      <h2 className="text-xl font-bold text-primary mb-2">4. No Exceptions:</h2>
      <p className="text-accent mb-4">
        Trading Beacon strictly adheres to a no-refund policy. Regardless of the reason for cancellation or dissatisfaction, refunds will not be granted.
      </p>

      <h2 className="text-xl font-bold text-primary mb-2">5. Contact Us:</h2>
      <p className="text-accent mb-4">
        If you have any questions or concerns regarding our Refund Policy, please feel free to contact us at <span className="font-bold">9883028266</span>. Our team is here to assist you and address any inquiries you may have.
      </p>

      <h2 className="text-xl font-bold text-primary mb-2">6. Updates to the Policy:</h2>
      <p className="text-accent mb-4">
        Trading Beacon reserves the right to update, modify, or change this Refund Policy at any time without prior notice. It is your responsibility to review this policy periodically for any updates or changes.
      </p>

      <p className="text-accent mb-4">
        By making a purchase from Trading Beacon, you agree to abide by our Refund Policy. Your satisfaction is important to us, and we are committed to providing exceptional products and services to our customers.
      </p>

      <p className="font-bold text-primary">Thank you for your understanding.</p>

      <p className="font-bold text-primary">Trading Beacon</p>
    </div>
  );
};

export default RefundPolicy;
