import React from "react";
import BenefitCardBengali from "./BenefitCardBengali";
import { useRef, useEffect } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

function CourseBenefitsBengali() {
  const courseBenefits = [
    {
      benefit: "RECORDED CONTENT",
      desc: "আপনার সুবিধা জন্য রয়েছে রেকর্ডেড কন্টেন্ট যা আপনি যখন তখন অ্যাকসেস করতে পারবেন।",
      emoji: "🎥",
    },
    {
      benefit: "LIVE CLASS",
      desc: "রয়েছে আপনাদের জন্য লাইভ ক্লাসেস।",
      emoji: "🔴",
    },
    {
      benefit: "LIVE MARKET SUPPORT WHATSAPP",
      desc: "রয়েছে লাইভ মার্কেট সাপোর্ট|",
      emoji: "💬",
    },
    {
      benefit: "DOUBT SESSIONS",
      desc: "আপনাদের জন্য রয়েছে সাপ্তাহিক 2-3 ডাউট ক্লিয়ারিং শেশনস।",
      emoji: "❔",
    },
    {
      benefit: "MARKET VIEW",
      desc: "সাথে পেয়ে যাচ্ছেন মার্কেট ভিউ প্রত্যেক সপ্তাহে।",
      emoji: "💡",
    },
    {
      benefit: "CURRENT STOCK ANALYSIS",
      desc: "সাথে আরও পেয়ে যাচ্ছেন বর্তমান স্টক গুলোর অ্যানালাইসিস।",
      emoji: "📉",
    },
  ];

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);
  return (
    <div
      ref={ref}
      className="w-[92%] md:w-[88%] lg:w-[1185px] m-auto transition-all duration-500 pt-12 pb-16"
    >
      <div className="">
        <h2 className=" font-lekton text-3xl sm:text-4xl pb-6 sm:pb-14 text-primary font-bold">
          কোর্সের বৈশিষ্ট্য
        </h2>
        <motion.ul
          variants={container}
          initial="hidden"
          animate={mainControls}
          className="grid grid-cols-1 sm:grid-cols-2 sm:grid-rows-3 lg:grid-rows-2 lg:grid-cols-3 gap-x-4 gap-y-3 items-stretch"
        >
          {courseBenefits.map((data) => (
            <motion.li variants={item}>
              <BenefitCardBengali
                desc={data.desc}
                benefit={data.benefit}
                emoji={data.emoji}
              />
            </motion.li>
          ))}
        </motion.ul>
      </div>
    </div>
  );
}

export default CourseBenefitsBengali;
