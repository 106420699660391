import React from "react";
import groupScreenshots from "../images/grouped.png";
import groupScreenshots2 from "../images/grouped-all.png";
import whatsappIcon from "../images/socials/whatsapp.png";
import telegramIcon from "../images/socials/telegram.png";
import Footer from "../components/Footer";
import Newsletter from '../components/Newsletter'
import { useNavigate } from "react-router";

function Community({setOpenModal}) {
  const navigate = useNavigate();

  return (
    <div className="mt-20 ">
      <div className="w-[85%] lg:w-[1185px] m-auto transition-all duration-500">
        <h1 className="text-3xl md:text-5xl text-center font-primary text-white ">
          JOIN INDIA’S #1 STOCK TRADING COMMUNITY
        </h1>
        <h1 className="text-3xl md:text-5xl text-center font-primary text-primary mt-2">
          OUR TRADING COMMUNITY
        </h1>

        <div className="flex justify-center mt-8">
          <a href="https://t.me/tradingbeaconbengali" target="_blank">
            <img
              src={groupScreenshots2}
              alt=""
              className="hover:cursor-pointer"
            />
          </a>
        </div>

        <div className="mt-16 rounded-3xl border-2 border-primary flex flex-col gap-3 md:gap-0 md:flex-row p-6 px-8 md:p-12 md:px-14">
          <div className="basis-2/5">
            <img
              src={whatsappIcon}
              alt=""
              className="h-24 md:h-80 hover:cursor-pointer"
            />
          </div>

          <div className="basis-3/5 flex-col items-between">
            <div className="">
              <p className="text-2xl md:text-4xl font-primary text-white ">
                <span className="text-primary">BEACONTRADES HUB:</span>
                <br /> ILLUMINATE YOUR LEARNING PATH ON WHATSAPP!
              </p>
              <p className="font-lekton text-white mt-4">
                Join our vibrant stock trading community on WhatsApp! Gain
                valuable insights, discuss market trends, and enhance your
                trading skills.
              </p>
            </div>

            <div className="basis-1/2 mt-8 ">
              <div className="basis-1/2 rounded-3xl px-2 py-3 md:px-5 md:py-4 w-[50%] bg-bb border-2 border-primary  text-accent font-bold text-center hover:cursor-pointer hover:bg-primary hover:text-bb transition-all duration-200">
                <a href="https://chat.whatsapp.com/KRL49yQRxFL1J1L6t9hmIc" target="_blank">Join Group!</a>
              </div>
            </div>
          </div>
        </div>

        <div className="rounded-3xl border-2 border-primary flex flex-col gap-3 md:gap-0 md:flex-row p-6 px-8 md:p-12 md:px-14 mt-10">
          <div className="basis-3/5 flex-col items-between order-last sm:order-first">
            <div className="">
              <p className="text-2xl md:text-4xl font-primary text-white ">
                <span className="text-primary">TRADEBEACON SOCIETY:</span>
                <br />
                GUIDING YOUR TRADING JOURNEY ON TELEGRAM!
              </p>
              <p className="font-lekton text-white mt-4">
                Join our vibrant stock trading community on WhatsApp! Gain
                valuable insights, discuss market trends, and enhance your
                trading skills.
              </p>
            </div>

            <div className="basis-1/2 mt-8 ">
              <div className="basis-1/2 rounded-3xl px-4 py-3 md:px-5 md:py-4 w-max md:w-[50%] bg-bb border-2 border-primary  text-accent font-bold text-center hover:cursor-pointer hover:bg-primary hover:text-bb transition-all duration-200">
              <a href="https://t.me/tradingbeaconbengali" target="_blank">Join Channel Now!</a>

                
              </div>
            </div>
          </div>

          <div className="basis-2/5 flex justify-start sm:justify-end order-first sm:order-last">
            <img
              src={telegramIcon}
              alt=""
              className="h-28 md:h-80 hover:cursor-pointer"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-4 w-[88%] lg:w-[1185px] m-auto mt-20 sm:mb-20 ">
        <div className="rounded-3xl  bg-primary px-8 py-10 sm:px-10 sm:py-12 basis-1/2">
          <h1 className="text-3xl md:text-5xl   text-cc font-primary">
            PERSONAL 1:1
            <br />
            MENTORSHIP. 👨🏻‍🏫
          </h1>

          <p className="mb-16 mt-3 font-lekton">
            Unlock personalized support and mentorship designed to fuel your
            individual growth, providing insights, and helping you overcome
            challenges on your unique journey.
          </p>

          <div className="mt-4 flex flex-col md:flex-row gap-3">
            <a href="https://wa.link/6if4y9" target="_blank" className="basis-1/2 rounded-3xl px-5 py-4 border bg-accent text-bb font-bold text-center text-sm md:text-lg hover:cursor-pointer hover:bg-bb hover:text-accent transition-all duration-200">
              Know More!
            </a>
            <a href="https://wa.link/6if4y9" target="_blank" className="basis-1/2 rounded-3xl px-5 py-4 border bg-accent text-bb font-bold text-center text-sm md:text-lg hover:cursor-pointer hover:bg-bb hover:text-accent transition-all duration-200">
              Connect
            </a>
          </div>
        </div>

        <div className="rounded-3xl bg-primary px-8 py-10 sm:px-10 sm:py-12 basis-1/2">
          <h1 className="text-3xl md:text-5xl  text-cc font-primary">
            FLEXIBLE
            <br />
            LEARNING. 👨🏻‍🎓
          </h1>

          <p className="mb-16 mt-3 font-lekton">
            Embrace a learning experience that molds to your lifestyle, allowing
            you to explore content at your own pace, ensuring a tailored
            educational path that suits your preferences.
          </p>

          <div className="mt-4 flex flex-col md:flex-row gap-3">
            <div 
            onClick={()=>navigate('/courses')}
            className="basis-1/2 rounded-3xl px-5 py-4 border bg-accent text-bb font-bold text-center text-sm md:text-lg hover:cursor-pointer hover:bg-bb hover:text-accent transition-all duration-200">
              Join Mentorship
            </div>
            <a 
            href="https://wa.link/6if4y9"
            target="_blank"
            className="basis-1/2 rounded-3xl px-5 py-4 bg-primary border-2 border-bb  text-bb text-sm md:text-lg font-bold text-center hover:cursor-pointer hover:bg-bb hover:text-accent transition-all duration-200">
              Let's chat
            </a>
          </div>
        </div>
      </div>

      <Newsletter setOpenModal={setOpenModal}/>

      <Footer />
      {/* <Footer2/> */}
    </div>
  );
}

export default Community;
