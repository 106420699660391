import React from 'react'
import { useNavigate } from 'react-router'

function NotFound() {
  const navigate = useNavigate()
  return (
    <div className='h-screen flex justify-center items-center'>
        <div>
          <h1 className='text-3xl md:text-7xl font-primary text-primary'>⚠ 404 Not Found</h1>
          <div className="mt-8 w-[80%] flex m-auto flex-col md:flex-row gap-3">
            <div onClick={()=>navigate('/')}
             className="basis-1/2 rounded-3xl px-5 py-4 border bg-accent text-bb font-bold text-center text-sm md:text-lg hover:cursor-pointer hover:bg-bb hover:text-accent transition-all duration-200">
              Go back!
            </div>
            <div className="basis-1/2 rounded-3xl px-5 py-4 bg-primary border-2 border-bb  text-bb font-bold text-center text-sm md:text-lg hover:cursor-pointer hover:bg-bb hover:text-accent transition-all duration-200">
              Connect
            </div>
          </div>
        </div>
    </div>
  )
}

export default NotFound
