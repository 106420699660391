import React from 'react'
import pointer from '../assets/pointer.svg'

function FeatureCard({feature, desc, logo}) {
  return (
    <div className='p-10 h-full bg-kk rounded-3xl hover:ring-2 ring-primary cursor-pointer hover:transition-all duration-200'>
        <div className='rounded-full bg-bb w-max p-5'>
              <img src={logo} alt="" height='2.5rem' width='42px'/>
        </div>

        <h1 className='mt-4 font-lekton text-lg sm:text-2xl'>{feature}</h1>

        <p className='font-primary mt-2 sm:mt-6 text-gray-500 text-sm sm:text-lg '>{desc}</p>
    </div>
  )
}

export default FeatureCard
