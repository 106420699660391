import React from 'react'
import Accordian from './Accordian'

export default function FAQs() {
  const courseFAQ = [
          {
            question: "How is the course content delivered?",
            answer: "The course content is delivered through a combination of video (recorded + live) lectures and supplementary materials. Participants can learn at their own pace and revisit the material as needed.",
          },
          {
            question: "Is there a certification upon completion of the course?",
            answer: "Yes, participants who successfully complete the course will receive a certificate of completion, which can be a valuable addition to their professional profile.",
          },
          {
            question: "What are the technical requirements for taking the course?",
            answer: "All you need is a stable internet connection and a device (computer or laptop) that can access our online platform.",
          },
          {
            question: "Is there any support available during the course?",
            answer: "Yes, our team is available to provide support and assistance. You can reach out through the online platform or contact us via email at [web.saifrabbani@gmail.com], Whatsapp:- 9883028266.",
          },
          {
            question: "Are there live Q&A sessions or forums for discussion?",
            answer: "Yes, we organize live Q&A sessions, and participants can also engage in discussions through the online forums. This allows for interaction with instructors and fellow participants.",
          },
          {
            question: "What are the payment options?",
            answer: "We accept payments through UPI, Netbanking, Debit & Credit cards. You can find detailed information on payment options during the registration process.",
          },
          {
            question: "Is there a refund policy?",
            answer: "No, please refer to our refund policy for detailed information on our refund process.",
          },
          {
            question: "What if I experience technical issues during the course?",
            answer: "If you encounter technical difficulties, please reach out to our technical support team at [web.saifrabbani@gmail.com] Whatsapp:- 9883028266. We will promptly assist you in resolving any issues.",
          },
          // ... Additional questions and answers
  ];
        
  const mentorshipFAQ = [
    {
      question: "What is the difference between your Equity and Derivative Trading Mentorship?",
      answer: "In the Equity Trading Mentorship, you will learn everything about equity trading, including swing trading and intraday trading. The comprehensive content covers trading from basic to advanced levels in equity with all necessary resources. The Derivative Trading Mentorship includes content from the basic course and delves into futures and options, specifically focusing on derivative trading.",
    },
    {
      question: "Do I need to pay the full amount for the combo course if I have already taken the basic course?",
      answer: "No, if you have already taken the basic course, you only need to pay the remaining amount for the combo course.",
    },
    {
      question: "What is the course duration?",
      answer: "The Equity Trading Mentorship has a duration of two months, while the advanced course spans three months.",
    },
    {
      question: "What if I face any doubts in any topics?",
      answer: "A dedicated support team is available to help you clear all your doubts via phone calls, WhatsApp messages, and through dedicated live sessions.",
    },
    {
      question: "Will the Mentorship make me a profitable or professional trader?",
      answer: "The course doesn't guarantee you immediate profitability, but it assures comprehensive learning in equity and derivative trading. Your success depends on your practice, efforts, and how well you apply the teachings to become a successful trader.",
    },
    {
      question: "How much money can I make after completing this course?",
      answer: "There are no guaranteed profits mentioned. The Mentorship provides technical and fundamental knowledge along with practical insights. Your earnings depend on your practice and efforts applied to the teachings.",
    },
    {
      question: "Will I get a certificate after completing this Mentorship?",
      answer: "Yes, you will receive a certificate upon completion.",
    },
    {
      question: "Do you provide offline courses?",
      answer: "Currently, we only offer online courses. Any future offline courses will be duly notified.",
    },
    {
      question: "What if I miss any live session?",
      answer: "If you miss a live session, the topic will be covered in another session or you can contact our support team for access to the recorded video.",
    },
    {
      question: "Will I get any assistance or support after completing this Mentorship?",
      answer: "Yes, after completing the course, you will receive continued support and assistance till next 1 year from our team. If you have any doubts, feel free to reach out, and our team will be happy to help.",
    },
    // ... Additional questions and answers
  ];
              
  return (
        <div className='w-[88%] lg:w-[1185px] m-auto'>
                <h2 className='text-white font-lekton text-2xl md:text-4xl mt-12 mb-6 sm:mt-20 sm:mb-8'>Frequently Asked Questions</h2>
                <h2 className='text-primary font-primary text-3xl mt-4 mb-4 sm:mt-8 sm:mb-8 text-center'>General</h2>

                <div className='w-[95%] md:w-[75%] m-auto'>
                  <div className=' bg-accent rounded-lg p-4'>
                          {mentorshipFAQ.map(data=><Accordian question={data.question} answer={data.answer}/>)}
                  </div>
                </div>



                <h2 className='text-primary font-primary text-3xl mt-14 mb-8 text-center'>Course Specific</h2>

                <div className='w-[95%] md:w-[75%] m-auto'>
                  <div className=' bg-accent rounded-lg p-4'>
                          {courseFAQ.map(data=><Accordian question={data.question} answer={data.answer}/>)}
                  </div>
                </div>


        </div>

  )
}
