import React from 'react'
import Marquee from 'react-fast-marquee'

import vm from '../images/featured/verna_magazine.png'
import ani from '../images/featured/ANI_5911.jpg'
import bs from '../images/featured/business-standard-logo-2.png'
import ed from '../images/featured/ed_times.jpg'
import gs from '../images/featured/gobestats.jpg'
import tm from '../images/featured/times_bulletin.png'
import '../App.css'

function FeaturedOnMarquee() {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  return (
    <div className='[mask-image:_linear-gradient(to_right,transparent_0,_black_20px,_black_calc(100%-40px),transparent_100%)]
    md:[mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)]'>
      <Marquee speed="45" >
        <div className='flex  gap-16  sm:gap-24 '>
                <div className="image-wrapper ml-16">
                        <img src={vm} alt="" className='w-12 h-12 sm:w-20 sm:h-20 hover:cursor-pointer' onClick={()=>openInNewTab("https://www.timebulletin.com/saif-rabbani-a-young-entrepreneur-kindling-inspiration-among-youth-as-well-as-budding-traders/")}/>
                </div>
                <div className="image-wrapper">
                        <img src={ani} alt="" className='w-12 h-12 sm:w-20 sm:h-20 hover:cursor-pointer' onClick={()=>openInNewTab("https://www.aninews.in/news/business/business/saif-rabbani-shares-lessons-in-stock-trading-that-can-turn-people-into-successful-stock-market-entrepreneurs20210330133631/")}/>
                </div>
                <div className="image-wrapper">
                        <img src={bs} alt="" className='w-22 h-10 sm:h-20 hover:cursor-pointer' onClick={()=>openInNewTab("https://www.business-standard.com/content/press-releases-ani/saif-rabbani-shares-lessons-in-stock-trading-that-can-turn-people-into-successful-stock-market-entrepreneurs-121033000586_1.html")}/>
                </div>
                <div className="image-wrapper">
                        <img src={ed} alt="" className='w-12 h-12 sm:w-20 sm:h-20 hover:cursor-pointer' onClick={()=>openInNewTab("https://edtimes.in/meet-saif-rabbani-making-his-mark-in-the-vast-financial-markets-as-a-true-blue-professional-and-trader/")}/>
                </div>
                <div className="image-wrapper">
                        <img src={tm} alt="" className='w-12 h-12 sm:w-20 sm:h-20 hover:cursor-pointer' onClick={()=>openInNewTab("https://www.timebulletin.com/saif-rabbani-a-young-entrepreneur-kindling-inspiration-among-youth-as-well-as-budding-traders/")}/>
                </div>
                <div className="image-wrapper">
                        <img src={gs} alt="" className='w-12 h-12 sm:w-20 sm:h-20 hover:cursor-pointer' onClick={()=>openInNewTab("https://www.globestats.com/saif-rabbani-heads-towards-major-gains-derived-through-stock-market-trading/")}/>
                </div>
                <div className="image-wrapper">
                        <img src={vm} alt="" className='w-12 h-12 sm:w-20 sm:h-20 hover:cursor-pointer' onClick={()=>openInNewTab("https://www.timebulletin.com/saif-rabbani-a-young-entrepreneur-kindling-inspiration-among-youth-as-well-as-budding-traders/")}/>
                </div>
                <div className="image-wrapper">
                        <img src={ani} alt="" className='w-12 h-12 sm:w-20 sm:h-20 hover:cursor-pointer' onClick={()=>openInNewTab("https://www.aninews.in/news/business/business/saif-rabbani-shares-lessons-in-stock-trading-that-can-turn-people-into-successful-stock-market-entrepreneurs20210330133631/")}/>
                </div>
                <div className="image-wrapper">
                        <img src={bs} alt="" className='w-22 h-10 sm:h-20 hover:cursor-pointer' onClick={()=>openInNewTab("https://www.business-standard.com/content/press-releases-ani/saif-rabbani-shares-lessons-in-stock-trading-that-can-turn-people-into-successful-stock-market-entrepreneurs-121033000586_1.html")}/>
                </div>
                <div className="image-wrapper">
                        <img src={ed} alt="" className='w-12 h-12 sm:w-20 sm:h-20 hover:cursor-pointer' onClick={()=>openInNewTab("https://edtimes.in/meet-saif-rabbani-making-his-mark-in-the-vast-financial-markets-as-a-true-blue-professional-and-trader/")}/>
                </div>
                <div className="image-wrapper">
                        <img src={tm} alt="" className='w-12 h-12 sm:w-20 sm:h-20 hover:cursor-pointer' onClick={()=>openInNewTab("https://www.timebulletin.com/saif-rabbani-a-young-entrepreneur-kindling-inspiration-among-youth-as-well-as-budding-traders/")}/>
                </div>
                <div className="image-wrapper">
                        <img src={gs} alt="" className='w-12 h-12 sm:w-20 sm:h-20 hover:cursor-pointer' onClick={()=>openInNewTab("https://www.globestats.com/saif-rabbani-heads-towards-major-gains-derived-through-stock-market-trading/")}/>
                </div>
        </div>

      </Marquee>
    </div>
  )
}

export default FeaturedOnMarquee
