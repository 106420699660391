import React from "react";
import YouTube from "react-youtube";

function YoutubeLink({title}) {
  const opts = {
    height: "530",
    width: "1024",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };
  const opts2 = {
    height: "200",
    width: "320",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };
  return (
    <div>
      {title && <div className=" flex items-end justify-center pt-8 md:pt-20 pb-16 sm:pb-24">
        <p className="px-4 sm:px-0 text-2xl md:text-5xl  text-white font-primary">
          {title}
        </p>
      </div>}

      <div className=" mb-28 hidden xl:flex justify-center items-center">
        <YouTube videoId="bWDp5MJqYjM" opts={opts} />;
      </div>
      <div className=" mb-28 flex justify-center items-center  xl:hidden">
        <YouTube videoId="bWDp5MJqYjM" opts={opts2} />;
      </div>
    </div>
  );
}

export default YoutubeLink;
