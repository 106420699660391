import React from 'react'

function Goals({title}) {
  return (
    <div>
        <div className='h-20 border-t-2 rounded-3xl border-primary'>
                <h3 className='text-gray-300 font-lekton p-4 text-md md:text-xl'>{title}</h3>
        </div>  
    </div>
  )
}

export default Goals
