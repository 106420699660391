import React from 'react'
import BenefitCard from './BenefitCard'

function CourseBenefits() {
  const courseBenefits = [
    {
      benefit: 'Exact Knowledge Tailored to You',
      emoji: '💡',
    },
    {
      benefit: 'Simplified Mindset, No Noise',
      emoji: '🧠',
    },
    {
      benefit: 'Recorded & Live Content',
      emoji: '🎥',
    },
    {
      benefit: 'Dedicated Personal Mentors',
      emoji: '👨🏻‍🏫',
    },
    {
      benefit: 'Live Market Guidance',
      emoji: '📈',
    },
    {
      benefit: 'High-Quality Content, No Fluff',
      emoji: '💎',
    },
  ];
  
  return (
    <div className='w-[88%] lg:w-[1185px] m-auto transition-all duration-500'>
      <div className=''>
        <h2 className='text-white font-lekton text-3xl sm:text-4xl pb-6 sm:pb-14'>COURSE BENEFITS</h2>
        <div className='grid grid-cols-1 sm:grid-cols-2 sm:grid-rows-3 lg:grid-rows-2 lg:grid-cols-3 gap-x-4 gap-y-3 items-stretch'>
          {courseBenefits.map(data=><BenefitCard benefit={data.benefit} emoji={data.emoji}/>)}
        </div>
      </div>
      
    </div>
  )
}

export default CourseBenefits
