import React from 'react'
import pic from '../images/saif.png'


function TestimonialCard({message,name,avatarUrl}) {
  return (
    <div className='mr-6 rounded-3xl border-primary border-2 py-6 px-8 sm:py-8 sm:px-10 font-primary w-[366px] max-h-max'>
      <div className='flex flex-col h-full justify-between'>
        <p className='text-accent text-start text-md sm:text-lg mb-4 '>
          {message}
        </p>

              
      <div className='flex gap-4 justify-start mt-8'>
        <div>
                <img src={avatarUrl} alt="" className='rounded-full w-12 h-12 object-cover'/>
        </div>

        <div className=''>
                <h3 className='text-accent sm:text-sm'>{name}</h3>
                <p className='text-gray-400 sm:text-sm'>Student</p>
        </div>
      </div>
      </div>


    </div>
  )
}

export default TestimonialCard
