import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

function CourseTimelineBengali({howToJoin}) {


  return (
    <div className="font-primary mt-32 pb-24 sm:pb-32">
      <p className="text-3xl sm:text-5xl text-center  text-white font-primary mb-16">
      কোর্স পদক্ষেপ:
      </p>
      <VerticalTimeline lineColor="#2b2b2b">
        {howToJoin.map((data) => {
          return (
            <VerticalTimelineElement
              className="vertical-timeline-element--eduation"
              contentStyle={{
                background: "none",
                color: "#fff",
                border: "none",
              }}
              contentArrowStyle={{ borderRight: "0px solid" }}
              iconStyle={{
                background: "#B9F900",
                color: "#fff",
                alignContent: "center",
                scale: "0.4",
              }}
              style={{ border: "none" }}
            >
              <div className="flex gap-8">
                <h1 className="font-primary text-5xl text-primary">
                  {data.id}
                </h1>
                <h3>{data.step}</h3>
              </div>
            </VerticalTimelineElement>
          );
        })}
      </VerticalTimeline>
    </div>
  );
}

export default CourseTimelineBengali;
