import React from 'react'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

function CourseTimeline() {
      const courseProcedureData = [
        { id: 1, data: "Upon enrollment, you will be assigned a mentor who will guide you throughout the program." },
        { id: 2, data: "You will receive your login credentials or a link to access the course materials." },
        { id: 3, data: "Recorded content will be provided topic-wise, following a predetermined sequence." },
        { id: 4, data: "After completing each topic, you are required to report your progress to your mentor." },
        { id: 5, data: "Live sessions will be conducted to elaborate on the recorded topics, address any doubts, and facilitate Q&A sessions." },
        { id: 6, data: "Practical application of the learned concepts is necessary after each topic, and your charts must be reviewed by the mentor." },
        { id: 7, data: "Trading activities are permitted only after receiving approval from your mentor." },
        { id: 8, data: "Upon completing all modules, you will be allowed to start trading with a very small quantity." },
        { id: 9, data: "Doubt sessions and practice sessions will be conducted to reinforce learning, followed by a focus on psychological aspects to kickstart your trading journey." }
        ];
              
  return (
    <div className='font-primary mt-32 mb-24 sm:mb-32'> 
          <p 
          className='text-3xl sm:text-5xl text-center  text-white font-primary mb-16'>
                HOW WILL BE MY JOURNEY?
          </p>
        <VerticalTimeline 
                lineColor='#2b2b2b'
        >
                {courseProcedureData.map(data=>{
                        return  <VerticalTimelineElement
                        className="vertical-timeline-element--eduation"
                        contentStyle={{ background: 'none', color: '#fff' , border:'none'}}
                        contentArrowStyle={{ borderRight: '0px solid' }}
                        iconStyle={{ background: '#B9F900', color: '#fff', alignContent:'center' ,scale:'0.4'}}
                        style={{border:'none'}}
                >     
                        <div className='flex gap-8'>
                                <h1 className='font-primary text-5xl text-primary'>{data.id}</h1>
                                <h3>{data.data}</h3>
                        </div>
                        </VerticalTimelineElement>
                        
                })}


        </VerticalTimeline>
      
    </div>
  )
}

export default CourseTimeline
