import React,{useRef} from 'react'
import {motion} from 'framer-motion'
import { useScroll, useTransform } from 'framer-motion'
import mockup from '../images/mockups/mockup.png'

function DeviceMockups() {

  //For Animation
  const ref = useRef(null)
  const {scrollYProgress} = useScroll({
        target: ref,
        offset: ["0 1", "1.20 1"]
        // offset: ["start start", "end start"]
  })
  const backgroundY = useTransform(scrollYProgress, [0,0], ["10%","30%"])


  return (
    <div ref={ref}  className='w-[78%] lg:w-[1185px] m-auto text-accent mt-12 sm:mt-20 '>
        <p 
          className='text-2xl sm:text-3xl md:text-5xl  text-white font-primary text-center '>
          LEARN - PRACTICE - EARN
        </p>

        <motion.div  className='flex justify-center items-center mt-12 sm:mt-16 '
                        animate={{
                                opacity: 100,
                                scale:1
                        }} 
                        initial= {{
                                opacity:0.5,
                                scale:0.5
                        }}
                        transition = {{
                                duration: 1     
                        }}>
                {/* <img src={iphone} alt="" className='h-1/2 mt-10 mr-[-140px] z-20'></img>
                <img src={imac} alt="" className='h-4/5' /> */}
                <img src={mockup} alt="" />
        </motion.div>
    </div>
  )
}

export default DeviceMockups
