import React from "react";
import Marquee from 'react-fast-marquee'
import r1 from "../images/reviews/1.jpg";
import r2 from "../images/reviews/2.jpg";
import r3 from "../images/reviews/3.jpg";
import r4 from "../images/reviews/4.jpg";
import r5 from "../images/reviews/5.jpg";
import r6 from "../images/reviews/6.jpg";
import r7 from "../images/reviews/7.jpg";
import r8 from "../images/reviews/8.jpg";
import r9 from "../images/reviews/9.jpg";
import r10 from "../images/reviews/10.jpg";
import r11 from "../images/reviews/11.jpg";
import r12 from "../images/reviews/12.jpg";
import r13 from "../images/reviews/13.jpg";

function Reviews() {
  return (
    // <div className="w-[78%] sm:w-[58%] m-auto">
    //     <h1 className='mt-28 sm:mt-32 text-2xl md:text-4xl font-primary text-center  text-accent'>TESTIMONIALS</h1>

    //   <div className=" flex justify-center items-center mt-6 sm:mt-16">
    //     <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
    //       <div class="grid gap-4">
    //         <div>
    //           <img class="h-auto max-w-full rounded-lg hover:cursor-pointer" src={r1} alt=""></img>
    //         </div>
    //         <div>
    //           <img class="h-auto max-w-full rounded-lg hover:cursor-pointer" src={r2} alt=""></img>
    //         </div>
    //       </div>
    //       <div class="grid gap-4">
    //         <div>
    //           <img class="h-auto max-w-full rounded-lg hover:cursor-pointer" src={r3} alt=""></img>
    //         </div>
    //         <div>
    //           <img class="h-auto max-w-full rounded-lg hover:cursor-pointer" src={r4} alt=""></img>
    //         </div>
    //       </div>
    //       <div class="grid gap-4">
    //         <div>
    //           <img class="h-auto max-w-full rounded-lg hover:cursor-pointer" src={r5} alt=""></img>
    //         </div>
    //         <div>
    //           <img class="h-auto max-w-full rounded-lg hover:cursor-pointer" src={r6} alt=""></img>
    //         </div>
    //       </div>
    //     </div>
    //   </div>

    //   {/* <Marquee speed='35'>
    //       <div className="flex gap-20">
    //           <div className="image-wrapper">
    //               <img src={r1} alt="" />
    //           </div>
    //           <div className="image-wrapper">
    //               <img src={r2} alt="" />
    //           </div>
    //           <div className="image-wrapper">
    //               <img src={r3} alt="" />
    //           </div>
    //           <div className="image-wrapper">
    //               <img src={r4} alt="" />
    //           </div>
    //           <div className="image-wrapper">
    //               <img src={r5} alt="" />
    //           </div>
    //           <div className="image-wrapper">
    //               <img src={r1} alt="" />
    //           </div>
    //           <div className="image-wrapper">
    //               <img src={r2} alt="" />
    //           </div>
    //       </div>
    //   </Marquee> */}
    // </div>

    <div>
         <h1 className='mt-0 md:mt-20 sm:mt-32 text-2xl md:text-4xl font-primary text-center  text-accent'>TESTIMONIALS</h1>
          <div className="[mask-image:_linear-gradient(to_right,transparent_0,_black_19px,_black_calc(100%-19px),transparent_100%)] sm:[mask-image:_linear-gradient(to_right,transparent_0,_black_68px,_black_calc(100%-100px),transparent_100%)]">
          <Marquee speed='30' className="mt-20" direction="right" pauseOnHover="true">
          <div className="flex gap-6 md:gap-16">
              <div className="image-wrapper ml-16">
                  <img src={r1} alt="" className="h-[25rem] md:h-[40rem] rounded-xl"/>
              </div>
              <div className="image-wrapper">
                  <img src={r2} alt="" className="h-[25rem] md:h-[40rem] rounded-xl"/>
              </div>
              <div className="image-wrapper">
                  <img src={r3} alt="" className="h-[25rem] md:h-[40rem] rounded-xl"/>
              </div>
              <div className="image-wrapper">
                  <img src={r4} alt="" className="h-[25rem] md:h-[40rem] rounded-xl"/>
              </div>
              <div className="image-wrapper">
                  <img src={r5} alt="" className="h-[25rem] md:h-[40rem] rounded-xl"/>
              </div>
              <div className="image-wrapper">
                  <img src={r6} alt="" className="h-[25rem] md:h-[40rem] rounded-xl" />
              </div>
              <div className="image-wrapper">
                  <img src={r7} alt="" className="h-[25rem] md:h-[40rem] rounded-xl" />
              </div>
              <div className="image-wrapper">
                  <img src={r8} alt="" className="h-[25rem] md:h-[40rem] rounded-xl" />
              </div>
              <div className="image-wrapper">
                  <img src={r9} alt="" className="h-[25rem] md:h-[40rem] rounded-xl" />
              </div>
              <div className="image-wrapper">
                  <img src={r10} alt="" className="h-[25rem] md:h-[40rem] rounded-xl" />
              </div>
              <div className="image-wrapper">
                  <img src={r11} alt="" className="h-[25rem] md:h-[40rem] rounded-xl" />
              </div>
              <div className="image-wrapper">
                  <img src={r12} alt="" className="h-[25rem] md:h-[40rem] rounded-xl" />
              </div>
              <div className="image-wrapper">
                  <img src={r13} alt="" className="h-[25rem] md:h-[40rem] rounded-xl" />
              </div>
          </div>
      </Marquee>
          </div>

    </div>
  );
}

export default Reviews;
