import React from 'react'
import Hero from '../components/Hero'
import Mentor from '../components/Mentor'
import Newsletter from '../components/Newsletter'
import Footer from '../components/Footer'
import DeviceMockups from '../components/DeviceMockups'
import Reviews from '../components/Reviews'
import Slogans from '../components/Slogans'
import Features from '../components/Features'
import Memberships from '../components/Memberships'
import YoutubeLink from '../components/YoutubeLink'
import DematLinks from '../components/DematLinks'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import logo from '../images/logo.png'

function Home({setOpenModal}) {
  return (
    <div className='max-w-max'>
       <FloatingWhatsApp phoneNumber='+91 9883028266' accountName='Trading Beacon' avatar={logo} statusMessage="We're here to help, fast"
      chatMessage='Hello! Curious about the stock market? Chat with us for expert insights, trade recommendations, or any questions you may have. '/>
      <Hero/>
      {/* <Slogans/>
      <Features/>
      <Memberships/>
      <YoutubeLink/> */}
      <DeviceMockups/>
      <Mentor/>
      <Reviews/>
      <DematLinks/>
      <Newsletter setOpenModal={setOpenModal}/>
      <Footer/>
    </div>
  )
}

export default Home
