import React, { useRef, useEffect } from "react";
import CourseTimelineBengali from "../components/CourseTimelineBengali";
import logo from "../images/logo.png";
import SingleCourseBengali from "../components/SingleCourseBengali";
import CourseBenefitsBengali from "../components/CourseBenefitsBengali";
import Footer from "../components/Newsletter";
import equityLogo from "../images/bengali/equity.jpg";
import derivativeLogo from "../images/bengali/derivative.jpg";
import HeroBengali from "../components/HeroBengali";

function Introduction() {
  const courseEligibility = [
    {
      info: "একটি ল্যাপটপ বা কম্পিউটার থাকতে হবে, কোর্স উপকরণ অ্যাক্সেস করার জন্য।",
    },
    {
      info: "ভালো ইন্টারনেট পরিষেবা অনিবার্য, কোর্স উপকরণ অ্যাক্সেস করার জন্য।",
    },
    { info: "একটি স্ট্যাবল ইনকাম সোর্স থাকতে হবে, বিনিয়োগ করার জন্য।" },
    // { info: "ইক্যুইটি মার্কেটে পূর্ববর্তী অভিজ্ঞতা থাকতে হবে।" },
  ];

  const courseSteps = [
    { step: "ফি Payment করুণ এনরোল করার জন্য।" },
    { step: "এনরোলমেন্ট ফর্মের জন্য ‘whatsapp- এ সরাসরি মেসেজ করুণ।" },
    { step: "ফর্ম পূরণ করুন এবং জমা দিন।" },
    {
      step: "24 ঘন্টার মধ্যে আপনার পছন্দের কোর্স সামগ্রী/ভিডিও ইত্যাদি পান।",
    },
  ];
  const howToJoin = [
    {
      id: 1,
      step: 'নিবন্ধনের পরে, আপনাকে একজন মেন্টর দেওয়া হবে যিনি আপনাকে প্রোগ্রামের সাথে পরিচালনা করবে।',
    },
    {
      id: 2,
      step: 'আপনি আপনার লগইন ক্রেডেন্শিয়াল বা কোর্স উপাদানগুলি অ্যাক্সেস করার জন্য একটি লিঙ্ক পাবেন।',
    },
    {
      id: 3,
      step: 'প্রেরিত ক্রমানুযায়ী, বিষয়ভিত্তিক রেকর্ডেড বিষয়গুলি প্রদান করা হবে।',
    },
    {
      id: 4,
      step: 'প্রতিটি বিষয় সম্পন্ন হওয়ার পরে, আপনার অগ্রগতি আপনার মেন্টরের কাছে প্রতিবেদন করতে হবে।',
    },
    {
      id: 5,
      step: 'রেকর্ডেড বিষয়গুলির উপর বিস্তারিত আলোচনা করতে, যেকোনো সন্দেহ দূর করতে এবং প্রশ্নত্তর অনুষ্ঠান সুবিধা করতে লাইভ সেশন অনুষ্ঠিত হবে।',
    },
    {
      id: 6,
      step: 'প্রতিটি বিষয়ের পরে শেখা সংজ্ঞানের প্রয়োজনীয় পরিকল্পনা, এবং আপনার চার্টগুলি মেন্টরের দ্বারা পর্যালোচনা করা আবশ্যক।',
    },
    {
      id: 7,
      step: 'মেন্টরের অনুমোদন পেয়ে শুধুমাত্র ট্রেডিং কার্যক্রম অনুমোদিত হবে।',
    },
    {
      id: 8,
      step: 'সমস্ত মডিউল সমাপ্ত হলে, আপনাকে খুব ছোট পরিমাণে ট্রেডিং শুরু করার অনুমতি দেওয়া হবে।',
    },
    {
      id: 9,
      step: 'শেখা সংজ্ঞানের পুনরাবৃত্তি ও অনুশীলন সেশন অনুষ্ঠিত হবে শেখার নিশ্চিততা বৃদ্ধিতে, পরবর্তীতে আপনার ট্রেডিং পথ শুরু করার মনোভাবিক দিক উল্লেখ করে।',
    },
  ];

  const freeCourseTopics = [
    {
      topic: "আইপিও",
      description: [
        "বাজারের অনুভূতি এবং সময়",
        "কোম্পানির মূল্যায়ন এবং বৃদ্ধির সম্ভাবনা",
        "ঝুঁকি এবং ভোলাটিলিটি",
      ],
    },
    {
      topic: "ডিভিডেন্ড",
      description: [
        "কোম্পানির লাভজনকতা",
        "লভ্যাংশ নীতি এবং স্থিতিশীলতা",
        "শেয়ারহোল্ডার পুরস্কার",
      ],
    },
    {
      topic: "ডিম্যাট একাউন্ট",
      description: [
        "ডিম্যাট অ্যাকাউন্টের গুরুত্ব",
        "বিনিয়োগ নিরীক্ষণ",
        "সিকিউরিটিজ ক্রয় এবং বিক্রয়",
      ],
    },
    {
      topic: "ডিপোজিটরি",
      description: ["ডিপোজিটরির সুবিধা", "NSDL এবং CDSL এর বর্ণনা"],
    },
    {
      topic: "ইনডেক্স এর অপরিহর্যতা",
      description: [
        "ইনডেক্স এর বর্ণনা",
        "ইনডেক্স এর প্রকার",
        "ইনডেক্স কে ডিকোড করা",
        "ইনডেক্স এর উদ্দেশ্য",
      ],
    },
    {
      topic: "ট্রেডিং এবং বিনিয়োগ",
      description: [
        "ট্রেডিং এর বর্ণনা এবং প্রকার",
        "ইনভেস্টিং এর বর্ণনা এবং প্রকার",
      ],
    },
    {
      topic: "ফান্ডামেন্টাল এবং টেকনিক্যাল এর গুরুত্বপূর্ণ",
      description: ["ফান্ডামেন্টাল এর তাৎপর্যতা", "টেকনিক্যাল এর গুরুত্বপূর্ণ"],
    },
  ];

  const equityTradingTopics = [
    { topic: "মার্কেটের ট্রেন্ড আইডেন্টিফাই করার পদক্ষেপ" },
    { topic: "পোটেনশিয়াল ইনভেস্টমেন্ট সুযোগগুলি অ্যানালাইসিস করা" },
    { topic: "রিস্ক ম্যানেজমেন্ট শিখা এবং লাভ নিশ্চিত করা" },
    { topic: "ভাল এবং খারাপ স্টক আইডেন্টিফাই করা" },
    { topic: "স্টক অ্যানালাইসিস করা ক্যান্ডেলস্টিক প্যাটার্নস দিয়ে" },
    { topic: "স্টক পোর্টফোলিও তৈরি এবং বজায় রাখা" },
    { topic: "পোর্টফোলিও ডাইভার্সিফিকেশনের গুরুত্ব" },
    { topic: "মাল্টিব্যাগার স্টকগুলি যাচাই করা" },
    { topic: "ম্যানিপুলেট করা হয় এমন স্টক থেকে নিজেকে রক্ষা করা" },
    { topic: "মার্কেট সেন্টিমেন্ট এবং সাইকোলজি সম্পর্কে জানা" },
    { topic: "রিস্ক ম্যানেজমেন্ট করা" },
    { topic: "স্টক সিলেকশনের পদক্ষেপ" },
    { topic: "পজিশন সাইজিং এর গুরুত্ব" },
    { topic: "বিভিন্ন ইনভেস্টিং স্ট্র্যাটেজি এবং তাদের ব্যাবহার" },
    { topic: "ফিবোনাচ্চিটি এবং এর ব্যাবহার" },
    { topic: "GAPS এর অ্যানালাইসিস করা" },
    { topic: "ট্রেডে প্রোপার এন্ট্রি, টার্গেট, এবং স্টপ লস সেট করা" },
    { topic: "প্রোপার সাপোর্ট এবং গাইডেন্স সাপেক্ষে রয়েছে" },
  ];

  const derivativesTradingTopics = [
    {
      topic: "জানুন অপশন ট্রেডিং কি হয় এবং কল ও পুট অপশন কাকে বলে।",
    },
    {
      topic:
        "জানুন অপশন গ্রীক ও অপশন ডেটা এবং এটি কীভাবে কাজ করে সে সম্পর্কে সমস্ত কিছু ।",
    },
    {
      topic: "ITM, OTM এবং ATM অপশনগুলি কী তা জানুন।",
    },
    {
      topic: "ওপেন ইন্টারেস্ট চার্ট এবং অপশন চেইন পড়তে শিখুন।",
    },
    {
      topic:
        "জানুন কিভাবে ভালো রিস্ক- রিওয়ার্ড এর জন্য আপনার চলমান ট্রেড পজিশনে অ্যাডজাস্টমেন্ট করবেন।",
    },
    {
      topic: "জানুন কীভাবে এবং কখন একটি অপশন বিক্রি করতে হয়।",
    },
    {
      topic: "ফিউচারস-এ কিভাবে ট্রেড করবেন তা শিখুন।",
    },
    {
      topic: "শিখুন কীভাবে অপশন - এর সাথে ফিউচারস ট্রেড করবেন তা ।",
    },
    {
      topic:
        "শিখুন বিভিন্ন স্ট্র্যাটেজিস এর সাহায্যে কীভাবে ফিউচারস এবং অপশন ট্রেডিং হেজ করতে হয়।",
    },
    {
      topic:
        "জানুন বিভিন্ন ডেরিভেটিভ স্ট্র্যাটেজি সম্পর্কে যেমন ক্রেডিট স্প্রেড, স্ট্র্যাঙ্গেল, স্ট্র্যাডল আরো অনেক কিছু।",
    },
    {
      topic: "জানুন বিভিন্ন ধরনের অপশন সেলিং টুলস সম্মন্ধে।",
    },
    {
      topic: "জানুন  নিজস্ব সাইকোলজি কে কিভাবে মজবুত করবেন।",
    },
    {
      topic: "শিখুন কীভাবে অপশন ট্রেডিং এ রিস্ক ম্যানেজ করতে হয়।",
    },
    {
      topic:
        "জানুন অপশন গ্রীকগুলি কি হয় এবং সেগুলো কিভাবে ট্রেডিং এ সাহায্য করে।",
    },
    {
      topic: "শিখুন কিভাবে অপশন ট্রেডিং এ রিস্ক ম্যানেজ করতে হয়।",
    },
  ];

  const equityTradingPoints = [
    {
      point:
        "📌আমাদের ইক্যুইটি মেন্টরশিপ ট্রেডিং প্রোগ্রামের মাধ্যমে আপনার ট্রেডিং সম্ভাবনা উন্মোচন করুন। আপনার নির্দিষ্ট প্রয়োজন অনুসারে আমাদের মেন্টোরের কাছ থেকে ব্যক্তিগতকৃত নির্দেশনা পান।",
    },
    {
      point:
        "📌আপনার নির্দিষ্ট ট্রেডিং চাহিদা মেটাতে উপযোগী প্রোগ্রাম। ইক্যুইটি সম্মন্ধে জানুন সমস্ত তথ্য। স্টকের ব্যাপারে ফান্ডামেন্টাল এবং টেকনিক্যাল বিশ্লেষণ করতে শিখুন। ইক্যুইটি ট্রেডিং স্ট্রাটেজি গুলির মধ্যে গভীর আলোচনা এবং বিশ্লেষণ। হাতে-কলমে অনুশীলন এবং ট্রেডিং টেকনিক বাস্তবায়ন।",
    },
  ];

  const derivativesTradingPoints = [
    {
      point:
        "📌আমাদের ডেরিভেটিভস মেন্টরশিপ ট্রেডিং প্রোগ্রামের মাধ্যমে আপনার ট্রেডিং ক্যারিয়ার কে সফল করে তুলু। আপনার নির্দিষ্ট প্রয়োজন অনুসারে আমাদের মেন্টোরের কাছ থেকে ব্যক্তিগতকৃত নির্দেশনা পান।",
    },
    {
      point:
        "📌আপনার নির্দিষ্ট ট্রেডিং চাহিদা মেটাতে উপযোগী প্রোগ্রাম।\nজানুন ডেরিভেটিভস ট্রেডিং সম্মন্ধে সমস্ত তথ্য।\nঅপশনস ডাটা বিশ্লেষণ করতে শিখুন।\nডেরিভেটিভস ট্রেডিং স্ট্রাটেজি গুলির মধ্যে গভীর আলোচনা এবং বিশ্লেষণ।\nহাতে-কলমে অনুশীলন এবং ট্রেডিং টেকনিক বাস্তবায়ন।",
    },
  ];

  const courseSection = useRef(null)

  return (
    <div>
      <div className="w-[88%] xl:w-[1185px] m-auto min-h-screen text-white">
        <HeroBengali courseSection={courseSection} />

        <CourseBenefitsBengali />

        <section ref={courseSection} id='allCourses' className="grid grid-cols-1 sm:grid-cols-2 gap-6 h-full ">
          <SingleCourseBengali
            logo={equityLogo}
            tradingPoints={equityTradingPoints}
            tradingTopics={equityTradingTopics}
            title="ইক্যুইটি মেন্টরশিপ প্রোগ্রাম"
            i='0'
          />
          <SingleCourseBengali
            logo={derivativeLogo}
            tradingPoints={derivativesTradingPoints}
            tradingTopics={derivativesTradingTopics}
            title="ডেরিভেটিভস মেন্টরশিপ প্রোগ্রাম"
            i='1'
          />
        </section>

        <div className="mt-8 basis-1/2 flex-grow min-h-32 h-full border-2 border-primary hover:border-accent rounded-3xl transition-all duration-200 flex flex-col justify-between ">
          <img
            src="https://images.unsplash.com/photo-1560221328-12fe60f83ab8?q=80&w=2074&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt=""
            className="h-[10rem] object-cover rounded-t-3xl"
          />

          <div className="px-6 py-4 sm:px-8 sm:py-6 text-2xl font-bold text-primary">
            <h1>FREE BASIC কোর্স <span className="animate-pulse">⚪</span></h1>
            <p className="text-sm mt-4 text-white font-normal leading-6">
              এই কোর্সটি বিশেষভাবে নতুনদের জন্য তৈরি করা হয়েছে যারা স্টক
              মার্কেটের সম্মন্ধে শিখতে আগ্রহ রয়েছে। এই পুরো প্রোগ্রাম জুড়ে,
              আমরা স্টক মার্কেটের বিষয়ে সব কিছুই আলোচনা করেছি যেমন কিভাবে স্টক
              মার্কেট কাজ করে তা নিয়ে, কোম্পানিগুলি কীভাবে স্টক মার্কেটে প্রবেশ
              করে তা বোঝা এবং একইসঙ্গে একটি রিটেইল অংশগ্রহণকারীর ভূমিকা, স্টক
              মার্কেট ফাংশনগুলির মেকানিক্সের পাঠোদ্ধার করা, প্রাথমিক পাবলিক
              অফারিংয়ের (আইপিও) সম্মন্ধে অন্বেষণ করা, ডিভিডেন্ড সম্পর্কে জানা,
              বা ট্রেডিংয়ের জটিলতাগুলি উন্মোচন করা। এই সমস্ত তথ্য এই কোর্সের
              মাধ্যমে প্রদান করা হয়েছে। এই কোর্সের শেষে, আপনি একটি দৃঢ় ভিত্তি
              এবং স্পষ্ট ধারণা অর্জন করবেন বা মার্কেট সম্মন্ধে পরিষ্কার বোঝার
              অধিকারী হবেন যা স্টক এবং ট্রেডিংয়ের বিশ্বকে সংজ্ঞায়িত করে।
            </p>
          </div>

          <div className="rounded-full h-[1px] w-[95%] m-auto bg-gray-600"></div>

          <div>
            <h1 className="px-6 py-4 sm:px-8 sm:py-6 text-lg font-bold text-primary">
              কোর্স অন্তর্ভুক্ত বিষয়
            </h1>
          </div>

          <div className="mb-1 sm:mb-4 px-6 py-2 sm:px-8 sm:py-2 pt-1 pb-6 grid grid-cols-0 sm:grid-cols-3">
            {freeCourseTopics.map((data) => {
              return (
                <div>
                  <div className="flex gap-3 leading-8">
                    <h1 className="text-primary">✔</h1>
                    <h1>{data.topic}</h1>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="w-full mt-20 flex justify-center items-center">
          <a className="rounded-3xl w-[80%] sm:w-[26%] px-4 py-4 border bg-primary text-bb font-bold text-center text-sm md:text-lg hover:cursor-pointer hover:bg-bb hover:text-accent transition-all duration-200"
          href="https://wa.link/kwar7b"
          target="_blank">
          👩‍🏫 FREE কোর্স!
          </a>
        </div>
        <div className="w-full mt-4 sm:mt-8 flex justify-center items-center animate-pulse">
          <a className="rounded-3xl w-[80%] sm:w-[26%] px-4 py-4 border bg-accent text-bb font-bold text-center text-sm md:text-lg hover:cursor-pointer hover:bg-bb hover:text-accent transition-all duration-200"
          href="https://rb.gy/lciejt"
          target="_blank">
          📒 ব্রোশিওর ডাউনলোড করুন!
          </a>
        </div>

        <CourseTimelineBengali howToJoin={howToJoin} />

        {/* Course Steps */}
        <div className="rounded-3xl  bg-bb border-2 border-primary text-white px-8 py-10 sm:px-10 sm:py-12 basis-1/2 pb-20 mb-2 sm:mb-8">
          <div className="flex flex-col justify-between">
            <div>
              <p className="text-3xl sm:text-5xl text-start  text-primary font-bold font-primary">
                কিভাবে জয়েন করবেন?
              </p>
              <ul className="pt-6">
                {courseSteps.map((data) => {
                  return (
                    <li className="text-lg text-white mb-4">✔️ {data.step}</li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>

        {/* Course Eligibility */}
        <div className="rounded-3xl  bg-primary px-8 py-10 sm:px-10 sm:py-12 basis-1/2 pb-20">
          <div className="flex flex-col justify-between">
            <div>
              <p className="text-3xl sm:text-5xl text-start  text-bb font-bold font-primary">
                কোর্স যোগ্যতা:
              </p>
              <ul className="pt-6">
                {courseEligibility.map((data) => {
                  return (
                    <li className="text-lg text-bb mb-4">✔️ {data.info}</li>
                  );
                })}
              </ul>
            </div>

            <div className="mt-4 flex flex-col md:flex-row gap-3">
              <a 
              href="https://rzp.io/i/Yw4NHMPt7"
              target="_blank"
              className="basis-1/2 rounded-3xl px-5 py-4 border bg-accent text-bb font-bold text-center text-sm md:text-lg hover:cursor-pointer hover:bg-bb hover:text-accent transition-all duration-200">
                Buy Courses
              </a>
              <a 
              href="https://wa.link/kwar7b"
              target="_blank"
              className="basis-1/2 rounded-3xl px-5 py-4 bg-primary border-2 border-bb  text-bb text-sm md:text-lg font-bold text-center hover:cursor-pointer hover:bg-bb hover:text-accent transition-all duration-200">
                Let's chat
              </a>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}

export default Introduction;
