import React, { useRef } from 'react'
import { useScroll, motion, useTransform } from 'framer-motion'
// import pic from '../images/saif_pro_pic.png'
import pic from '../images/saif.png'
import { useNavigate } from "react-router-dom";
import FeaturedOnMarquee from './FeaturedOnMarquee';

function Mentor() {
  // For Nav
  const navigate = useNavigate();

  //For Animation
  const ref = useRef(null)
  const ref2 = useRef(null)
  const {scrollYProgress} = useScroll({
        target: ref,
        offset: ["0 1", "1.40 1"]
        // offset: ["start start", "end start"]
  })
      
  const backgroundY = useTransform(scrollYProgress, [1,0], ["0%","30%"])
  const textY = useTransform(scrollYProgress, [0,1], ["50%","400%"])
        
  return (
        <div className='bg-blue-600 font-display'>    
            <div ref={ref} className='bg-bb h-[20rem]  md:min-h-screen flex items-start justify-center relative'>
                    <motion.h1 
                    style={{
                            // scale: scrollYProgress*40,
                            opacity: scrollYProgress,
                            y:textY
                    }}
                    className='mt-16 sm:mt-24 lg:mt-0 text-3xl sm:text-4xl md:text-6xl lg:text-8xl font-bold z-0 text-accent'>MEET YOUR MENTOR.</motion.h1>
    
                    <motion.div style={{
                            // backgroundImage: `url(${pic})`,
                            // backgroundSize: "cover",
                            // backgroundRepeat: 'no-repeat',
                            y:backgroundY
                    }} 
                    className='h-full w-full absolute inset-0 flex flex-col'>
                            <div className='flex items-center justify-center'>
                                <img src={pic} alt="" className='scale-[.48] md:scale-[.55] lg:scale-[.63] hover:cursor-pointer' onClick={()=>navigate('/about')}/>
                            </div>
                            <div className='-mt-40 w-full flex-col justify-center items-center'>
                                    <h1 className='mt-10 sm:mt-4 text-2xl sm:text-4xl font-bold text-white text-center'>Saif Rabbani</h1>
                                    <h1 className='text-lg sm:text-2xl font-lekton text-white text-center'>Certified Research Analyst</h1>
                            <div className='mt-20 w-full h-max p-2 bg-accent'>
                                <FeaturedOnMarquee/>
                            </div>
                            </div>

                    </motion.div>
            </div>
    
    
    
            <div className='bg-bb h-[19.8rem] flex items-center justify-center pt-40'>
            </div>
        </div>
      )
}

export default Mentor
