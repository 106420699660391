import React from 'react'

function BenefitCardBengali({desc,benefit, emoji}) {
        return (
                <div className='p-10 bg-kk rounded-3xl hover:ring-2 ring-primary cursor-pointer hover:transition-all duration-200 hover:cursor-pointer'>
                    <div className='rounded-full bg-bb w-max p-3'>
                          <h1 className='text-4xl w-12 h-12 text-center'>{emoji}</h1>
                    </div>
            
                    <h1 className='mt-4 font-lekton text-lg sm:text-2xl'>{benefit}</h1>
            
                    <p className='font-primary mt-2 sm:mt-6 text-gray-500 text-sm sm:text-md '>{desc}</p>
                </div>
              )
}

export default BenefitCardBengali
