import React, { useState } from "react";
import {X,Menu} from 'lucide-react'
import logo from '../images/logo.png'
import { Link, useNavigate } from 'react-router-dom'
import {motion, useScroll, useMotionValueEvent} from 'framer-motion'




const Navbar3 = () => {
  // State to manage the navbar's visibility
  const [nav, setNav] = useState(false);

  // Toggle function to handle the navbar's display
  const handleNav = () => {
    setNav(!nav);
  };

  // Array containing navigation items
  const navItems = [
    { id: 1, text: "Home" , nav: "/"},
    { id: 2, text: "About", nav:"/about" },
    { id: 3, text: "Courses",nav: "/courses" },
    { id: 4, text: "Community", nav: "/community" },
  ];

  const {scrollY} = useScroll()
  const [hidden, setHidden] = useState(false)

  const navigate = useNavigate()

  useMotionValueEvent(scrollY, "change", (latest)=>{
    const previous = scrollY.getPrevious()
    if(latest > previous && latest > 500){
      setHidden(true)
    }else{
      setHidden(false)
    }
  })

  return (
    <motion.nav 
    variants={{
        visible: {y:0},
        hidden: {y:"-100%"}
      }}
      animate={hidden ? "hidden" : "visible"}
      transition={{duration: 0.35, ease: "easeInOut"}}
    className='sticky top-0 z-40 min-h-16 sm:h-16 bg-bb/50 backdrop-blur-md border-b border-gray-800 text-accent flex items-center justify-center font-primary'>
      <div className="w-[78%] m-auto flex justify-between items-center">
        {/* Logo */}
       <Link to="/"><img src={logo} alt="logo-tb" className=' rounded-full h-12'/></Link> 


        {/* Desktop Navigation */}
        <ul className="hidden md:flex gap-5 sm:gap-x-20 md:items-center md:justify-center transition-all sm:visible duration-700 ">
          {navItems.map((item) => (
            <li
              key={item.id}
              onClick={()=>navigate(item.nav)}
              className=" hover:text-gray-200 rounded-xl  cursor-pointer duration-300 "
            >
              {item.text}
            </li>
          ))}
        </ul>
      <button
      onClick={()=>navigate('/courses')}
       className='hidden sm:block px-4 py-2 rounded-xl text-bb bg-primary hover:cursor-pointer hover:bg-accent hover:text-bb transition-all duration-200 '>Join Now</button>


        {/* Mobile Navigation Icon */}
        <div onClick={handleNav} className="block md:hidden hover:cursor-pointer">
          {nav ? <X size={20} /> : <Menu size={20} />}
        </div>

        {/* Mobile Navigation Menu */}
        <ul
          className={
            nav
              ? "z-40 fixed md:hidden left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-black ease-in-out duration-500"
              : "z-40ease-in-out w-[60%] duration-500 fixed top-0 bottom-0 left-[-100%]"
          }
        >

          {/* Mobile Navigation Items */}
          <ul className={`flex p-4 px-10  min-h-screen h-full bg-bb/90 backdrop-blur-md gap-5 pt-8 pb-8 flex-col sm:gap-x-20 items-center justify-start transition-all sm:visible duration-700  `}>
        <li className="w-full" onClick={()=>setNav(false)}><Link to="/">Home</Link></li>
        <li className="w-full" onClick={()=>setNav(false)}><Link to="/about">About</Link></li>
        <li className="w-full" onClick={()=>setNav(false)}><Link to="/courses">Courses</Link></li>
        <li className="w-full" onClick={()=>setNav(false)} ><Link to="/community">Community</Link></li>
      </ul>
        </ul>
      </div>
    </motion.nav>
  );
};

export default Navbar3;
