import React, { useState } from "react";

function Footer({ setOpenModal }) {
  const SCRIPT_URL =
    "https://script.google.com/macros/s/AKfycbx0y4FNdWayGIe91squVmWMqxcvC7ga7LT2xcNBeRjVmVia_mq8UFAP42nb3fu2l8Hvvg/exec";

  const form = document.forms["submit-to-google-sheet"];
  const handleFormSubmit = (e) => {
    e.preventDefault();
    fetch(SCRIPT_URL, { method: "POST", body: new FormData(form) })
      .then((response) => console.log("Success!", response))
      .catch((error) => console.error("Error!", error.message));
  };

  const [userEmail, setUserEmail] = useState("");

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  function isValidEmail(email) {
    return emailRegex.test(email);
  }

  const handleModal = () => {
    if (userEmail.length === 0) {
      setUserEmail("");
      // alert("Enter Email")
    } else if (isValidEmail(userEmail)) {
      setOpenModal(true);
    } else {
      setUserEmail("");
      // alert("Invalid email id")
    }
  };

  return (
    <div className="mt-10">
      <div className="flex w-max m-auto mb-10 sm:mb-0 px-8 py-4 flex-col justify-center items-center rounded-3xl border-2 border-primary">
        <h1 className="text-xl sm:text-3xl text-primary font-primary font-bold mb-6">
          সরাসরি যোগাযোগ করুন
        </h1>
        <div className="flex">
          <a href="tel:+919933904258"  className="text-accent font-lekton text-lg sm:text-3xl">📞9933904258/</a>
          <a href="tel:+919883028266" className="text-accent font-lekton text-lg sm:text-3xl" >9883028266</a>

        </div>
      </div>

      <p className="md:pt-28 text-2xl md:text-4xl font-primary text-center pb-20 text-accent ">
        MAKE SURE YOU ALWAYS RECEIVE
        <br />
        THE LATEST UPDATES.
      </p>

      <div className="">
        <form
          name="submit-to-google-sheet"
          onSubmit={(e) => handleFormSubmit(e)}
          className="w-full flex flex-col sm:flex-row gap-2 items-center justify-center"
        >
          <input
            onChange={(e) => setUserEmail(e.target.value)}
            type="text"
            placeholder="Enter your email"
            name="email"
            className="px-6 py-3 md:px-8 md:py-4 min-w-[50%] sm:min-w-max  rounded-lg bg-kk border-none focus:outline-none text-accent w-1/4"
          />
          <button
            type="submit"
            onClick={() => {
              document.body.scrollTop = document.documentElement.scrollTop = 0;
              handleModal();
            }}
            className="text-bb font-primary rounded-lg h-full bg-primary px-6 py-3 md:px-8 md:py-4"
          >
            SUBSCRIBE
          </button>
        </form>
      </div>

      <h1 className="pt-6 text-sm font-primary text-center pb-20 text-gray-500">
        Unleash your potential and embark on a journey towards financial
        independence.
        <br />
        Learn, trade, succeed with TradingBeacon
      </h1>

      {/* <div className='mt-20 pb-20  bg-kk text-white '>
          <div className='w-[1185px]  m-auto pt-14 pb-20 '>
            <h1 className='text-3xl font-primary text-primary'>Trading Beacon</h1>
          </div>

          <div className='flex justify-between w-[1185px] m-auto'>
            <div>
              <h2  className='text-bold font-primary text-xl mb-4'>Courses</h2>
              <ul className='text-sm flex flex-col gap-2'>
                <li><a href="#" className='font-primary hover:text-primary transition-colors duration-200 ease-out'>Features</a></li>
                <li><a href="#" className='font-primary hover:text-primary transition-colors duration-200 ease-out'>Equity Trading Program</a></li>
                <li><a href="#" className='font-primary hover:text-primary transition-colors duration-200 ease-out'>Derivatives Trading Program</a></li>
              </ul>
            </div>
            <div>
              <h2  className='text-bold font-primary text-xl mb-4'>Company</h2>
              <ul className='text-sm flex flex-col gap-2'>
                <li><a href="#" className='font-primary hover:text-primary transition-colors duration-200 ease-out'>About Us</a></li>
                <li><a href="#" className='font-primary hover:text-primary transition-colors duration-200 ease-out'>Contact Us</a></li>
                <li><a href="#" className='font-primary hover:text-primary transition-colors duration-200 ease-out'>Support</a></li>
              </ul>
            </div>
            <div>
              <h2  className='text-bold font-primary text-xl mb-4'>Social media</h2>
              <ul className='text-sm flex flex-col gap-2'>
                <li><a href="#" className='font-primary hover:text-primary transition-colors duration-200 ease-out'>Instagram</a></li>
                <li><a href="#" className='font-primary hover:text-primary transition-colors duration-200 ease-out'>Facebook</a></li>
                <li><a href="#" className='font-primary hover:text-primary transition-colors duration-200 ease-out'>Twitter</a></li>
                <li><a href="#" className='font-primary hover:text-primary transition-colors duration-200 ease-out'>LinkedIn</a></li>
              </ul>
            </div>
            <div>
              <h2  className='text-bold font-primary text-xl mb-4'>Policies</h2>
              <ul className='text-sm flex flex-col gap-2'>
                <li><a href="#" className='font-primary hover:text-primary transition-colors duration-200 ease-out'>T&C's</a></li>
                <li><a href="#" className='font-primary hover:text-primary transition-colors duration-200 ease-out'>Privay</a></li>
                <li><a href="#" className='font-primary hover:text-primary transition-colors duration-200 ease-out'>Refund</a></li>
              </ul>
            </div>


          </div>
        </div> */}
    </div>
  );
}

export default Footer;
