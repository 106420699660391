import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Navbar from './components/Navbar'
import Navbar3 from './components/Navbar3'
import Home from './pages/Home'
import Courses from './pages/Courses'
import AboutUs from './pages/AboutUs'
import RefundPolicy from './pages/RefundPolicy'
import Community from './pages/Community'
import CourseModules from './components/CourseModules'
import Navbar2 from './components/Navbar2'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import logo from './images/logo.png'
import Introduction from './pages/Introduction'
import ConfirmationModal from './components/ConfirmationModal'
import NotFound from './components/NotFound'




function App() {
  const [openModal, setOpenModal] = useState(false)

  return (
    <div className='bg-neutral-950 min-h-screen bg-[radial-gradient(ellipse_80%_80%_at_50%_-20%,rgba(120,119,198,0.3),rgba(255,255,255,0))]'>
      {/* {!openModal && <Navbar setOpenModal={setOpenModal}/>} */}
      {!openModal && <Navbar3/>}
      {openModal && <ConfirmationModal setOpenModal={setOpenModal}/>}
      {/* <Navbar2/> */}
      <Routes>
        <Route exact path="/" element={<Home setOpenModal={setOpenModal}/>}/>
        <Route exact path="/intro" element={<Introduction/>}/>
        <Route  path="/courses" element={<Courses/>}/>
        <Route  path="/courses/modules" element={<CourseModules/>}/>
        <Route  path="/about" element={<AboutUs setOpenModal={setOpenModal}/>}/>
        <Route  path="/community" element={<Community setOpenModal={setOpenModal}/>}/>
        <Route  path="/refund-policy" element={<RefundPolicy/>}/>
        <Route  path="*" element={<NotFound/>}/>
      </Routes>
    </div>
  )
}

export default App
