import React from 'react'
import CourseBenefits from '../components/CourseBenefits'
import CourseDescription from '../components/CourseDescription'
import YouTube from 'react-youtube'
import getYouTubeID from 'get-youtube-id'
import FAQs from '../components/FAQs'
import Footer from '../components/Footer'
import Testimonials from '../components/Testimonials'
import CourseTimeline from '../components/CourseTimeline'
import YoutubeLink from '../components/YoutubeLink'

function Courses() {
  const opts = {
    height: '530',
    width: '1024',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div>
      <div className=' flex items-end justify-center pt-20 pb-24'>
          <p 
          className='text-2xl sm:text-3xl md:text-5xl  text-white font-primary'>
          BECOME THE TRADER, YOU’VE<br/>
          ALWAYS WANTED TO BECOME
          </p>

      </div>

      <YoutubeLink/>


        <CourseBenefits/>

        <CourseDescription/>
        
        <CourseTimeline/>

        {/* Schedule a call */}
        <div className='rounded-3xl w-[88%] lg:w-[1185px] m-auto bg-primary px-8 py-10 sm:px-10 sm:py-12'>
          <h1 className='text-2xl md:text-5xl   text-cc font-primary mb-16'>
            Not ready to buy yet? <br />
            Give us a call or book an online meeting. 🤙
          </h1>

          <div className='mt-4 flex gap-2 flex-col sm:flex-row md:gap-6'>
            <a href="tel:+919883028266" className='basis-1/2 rounded-3xl text-md sm:text-lg px-4 py-3 sm:px-5 sm:py-4 border bg-accent text-bb font-bold text-center hover:cursor-pointer hover:bg-bb hover:text-accent transition-all duration-200'>Call Now!</a>
            <a href="https://wa.link/6if4y9" target='_blank' className='basis-1/2 '>
              <div className='rounded-3xl text-md sm:text-lg px-4 py-3 sm:px-5 sm:py-4 bg-primary border-2 border-bb  text-bb font-bold text-center hover:cursor-pointer hover:bg-bb hover:text-accent transition-all duration-200'>
                Let's Chat
              </div>
            </a>
          </div>

        </div>

        <FAQs/>

        <Footer/>

    </div>
  )
}

export default Courses
