import React from 'react'
import pointer from '../assets/pointer.svg'


function BenefitCard({benefit, emoji}) {
  return (
    <div className='bg-white rounded-3xl min-h-10 flex gap-5 px-5 py-6'>
        <div className='bg-primary  min-h-4 p-3 rounded-lg'>
          {/* <img src={pointer} alt="" height='2.2rem' width='40px'/>   */}
          <p className='text-4xl w-12 h-12 text-center'>{emoji}</p>          
        </div>

        <div className='flex justify-center items-center'>
          <h2 className='font-primary text-lg'>
            {benefit}
          </h2>
        </div>

      
    </div>
  )
}

export default BenefitCard
