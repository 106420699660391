import React from 'react'
import SingleCourseModule from './SingleCourseModule'
import SingleDematLinkButton from './SingleDematLinkButton'
import dhan from '../images/demat/dhan1.png'
import upstox from '../images/demat/upstox.png'
import angelone from '../images/demat/angelone.png'
import aliceblue from '../images/demat/aliceblue.png'
import zerodha from '../images/demat/zerodha.png'



function DematLinks() {
  const dematPlatformLinks = [
    { name: "ZERODHA", url: "https://zerodha.com/open-account?c=IJ3333", logo: zerodha },
    { name: "UPSTOX", url: "https://upstox.com/open-account/?f=4BA5JU", logo: upstox },
    { name: "ANGEL ONE", url: "http://tinyurl.com/ywr2tv5j", logo: angelone },
    { name: "DHAN", url: "https://invite.dhan.co/?invite=OBWLU37388", logo: dhan },
    { name: "ALICE BLUE", url: "https://app.aliceblueonline.com/OpenAccount.aspx?C=WNAG425", logo: aliceblue },
  ];
  
  
  return (
    <div className='text-white w-[88%] xl:w-[1185px] m-auto mt-20 sm:mt-36 mb-16 sm:mb-28'>
        <h2 className='font-primary text-2xl sm:text-3xl'>DON'T HAVE A DEMAT ACCOUNT YET? </h2>
        <p className='font-primary text-2xl sm:text-3xl'>CREATE ONE NOW!</p>

        <div className='flex flex-wrap justify-center md:justify-between mt-12 gap-2 sm:gap-4'>
                {
                  dematPlatformLinks.map((data)=>{
                    return <SingleDematLinkButton name={data.name} url={data.url} image={data.logo} key={data.url} />
                  })
                }
                {/* <SingleDematLinkButton name="UPSTOX" image={upstox}/>
                <SingleDematLinkButton name="DHAN" image={dhan}/>
                <SingleDematLinkButton name="ANGELONE" image={angelone}/>
                <SingleDematLinkButton name="ZERODHA" image={zerodha}/>
                <SingleDematLinkButton name="ALICEBLUE" image={aliceblue}/> */}
        </div>
    </div>
  )
}

export default DematLinks
