import React from "react";
import { useNavigate } from "react-router";

function Slogans() {
  const navigate = useNavigate()
  return (
    <div className="w-full">
      <div className="w-[88%] xl:w-[1185px] m-auto mt-20">
        <div className="w-full  flex flex-col lg:flex-row gap-4 h-full items-center">
          <h1 className="basis-5/7 text-xl md:text-3xl   text-accent font-primary mb-4 sm:mb-10 lg:mb-16">
            At Trading Beacon, we offer elite stock market courses and refined
            financial services, we illuminate the path to financial prowess,
            serving as a beacon for ambitious traders and investors.
          </h1>
          <div className="basis-2/7 h-full w-full flex justify-start items-start lg:justify-end lg:items-center">
            <div 
            onClick={()=>navigate('/courses')}
            className="px-8 lg:px-20 font-primary bg-primary text-bb font-bold rounded-3xl text-center p-4 hover:bg-white transition-all duration-200 hover:cursor-pointer">
              SEE COURSES
            </div>
          </div>
        </div>

        <div className="bg-primary rounded-3xl flex px-20 py-6 justify-center items-center mt-4 md:mt-0">
          <h3 className="text-bb text-sm md:text-xl font-primary">
            Free Courses <span className="ml-6 hidden sm:visible">|</span>
          </h3>
          <h3 className="text-bb text-sm md:text-xl font-primary ml-6">
            {" "}
            Mentorship Program<span className="ml-6 hidden sm:visible">|</span>
          </h3>
          <h3 className="text-bb text-sm md:text-xl font-primary ml-6">Market Analysis</h3>
        </div>
      </div>
    </div>
  );
}

export default Slogans;
