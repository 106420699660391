import React, { useEffect, useState } from 'react'

function Accordian({question, answer}) {
  const [accordianOpen, setAccordianOpen] = useState(false)

  return (
    <div className='py-2 font-primary'> 
      <button className='flex justify-between w-full'
      onClick={()=>setAccordianOpen(!accordianOpen)}>
        <span className='text-start'>{question}</span>
        
        {/* {accordianOpen ? <span>-</span> : <span>+</span>} */}
        <svg
          className="fill-gray-800 shrink-0 ml-8"
          width="16"
          height="16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            y="7"
            width="16"
            height="2"
            rx="1"
            className={`transform origin-center transition duration-200 ease-out ${
              accordianOpen && "!rotate-180"
            }`}
          />
          <rect
            y="7"
            width="16"
            height="2"
            rx="1"
            className={`transform origin-center rotate-90 transition duration-200 ease-out ${
              accordianOpen && "!rotate-180"
            }`}
          />
        </svg>
        
      </button>
      
      <div className={`grid overflow-hidden transition-all duration-300 ease-in-out text-slate-600 ${
        accordianOpen ? 'grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'
      }`}>
        <div className='overflow-hidden'>{answer}</div>
      </div>

      <div className='min-h-[1.1px] rounded-full mb-1 mt-1 w-full bg-gray-300/80'></div>

    </div>
  )
}

export default Accordian
