import React, { useEffect } from "react";
import { useState } from "react";
import stockImg from "../images/stock1.jpg";

function SingleCourseModule({ singleModuleData, moduleNum }) {
  useEffect(()=>{
    if(moduleNum == 0){
      console.log('setting true')
      setAccordianOpen(true)
    }
  },[])
  const [accordianOpen, setAccordianOpen] = useState(false);
  return (
    <div className="py-2 font-primary text-accent border-2 border-primary px-12 py-8 rounded-3xl mb-4">
      <button
        className="flex justify-between w-full"
        onClick={() => setAccordianOpen(!accordianOpen)}
      >
        <div>
          {/* <p clas sName="text-start font-lekton">{`MODULE ${moduleNum + 1}`}</p> */}
          <h3 className="font-primary text-xl text-start md:text-2xl">{`${singleModuleData.title}`}</h3>
        </div>

        <svg
          className={`fill-current h-4 w-4 md:h-6 md:w-6 transform transition-transform duration-200 ${
            accordianOpen ? "rotate-180" : "rotate-0"
          }`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M21.41 12.58L12 2.83 2.59 12.34a.996.996 0 101.41 1.41L12 5.66l8.99 7.99a.996.996 0 101.41-1.41z" />
        </svg>
      </button>

      <div
        className={`grid overflow-hidden transition-all duration-300 ease-in-out text-slate-600 ${
          accordianOpen
            ? "grid-rows-[1fr] opacity-100"
            : "grid-rows-[0fr] opacity-0"
        }`}
      >
        <div className="overflow-hidden flex flex-col md:flex-row mt-8 gap-8">
          <div className="basis-1/4 rounded-3xl">
            <img
              className="object-cover rounded-xl border border-accent/20 h-32 w-full md:h-40"
              src={stockImg}
              alt=""
            />
          </div>

          <div className="basis-3/4 flex flex-col gap-4 text-accent">
            {singleModuleData.description.map((data) => {
              return (
                <div className="flex gap-2 w-[70%]">
                  <h1 className="text-primary">✔</h1>
                  <h1>{data}</h1>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleCourseModule;
