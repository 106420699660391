import React from "react";
import { useNavigate } from "react-router";
import certification from "../assets/certification.svg";
import online from "../assets/Online.svg";
import level from "../assets/level.svg";
import duration from "../assets/duration.svg";
import charts from "../images/charts.jpg";
import { ShieldCheck, Cloud } from "lucide-react";

function CourseDescription() {
  const navigate = useNavigate();

  const handleOpenModules = () => {
    navigate("/courses/modules");
    window.scrollTo(0, 200);
  };

  const courseDataEmojis = [
    { emoji: "📝", title: "Certification" },
    { emoji: "🔰", title: "Beginner/Intermediate" },
    { emoji: "☁️", title: "Online" },
    { emoji: "📅", title: "~ 1 Month" },
  ];

  return (
    <div className="w-[88%] lg:w-[1185px] m-auto">
      {/* Course 1 */}
      <div className="font-lekton text-white flex gap-4 pt-14 pb-8">
        <h1 className="flex items-end text-primary text-5xl md:text-7xl font-bold">
          01
        </h1>
        <h1 className="text-start text-2xl md:text-3xl">
          EQUITY TRADING PROGRAM
        </h1>
      </div>

      <div className="text-white border-2 rounded-3xl border-primary mb-20">
        <div className="p-7 px-8 md:p-10 md:px-12 flex flex-col lg:flex-row">
          {/* Title */}
          <div className="relative basis-3/5 flex-col font-bold font-primary flex justify-center">
            <div>
              <h1 className="text-xl sm:text-3xl">ACTIONABLE SKILLS,</h1>
              <h1 className="text-3xl sm:text-5xl">INFINITE POSSIBILITES.</h1>
              <p className="text-accent/75 font-normal text-lg">
                Learn directly from the expert!
              </p>
            </div>

            {/* <div className="flex gap-1 w-full sm:w-1/2 justify-between  sm:visible md:gap-4 sm:absolute bottom-1">
              {courseDataEmojis.map((data)=>{
                return (<div className="flex-col items-center justify-center mt-8 mb-4 sm:mt-0">
                <div className="flex justify-center mb-1">
                  <h1
                    className="text-white text-center w-full h-10 text-2xl md:text-3xl"
                  >{data.emoji}</h1>
                </div>
                <h4 className="text-[11px] text-center">{data.title}</h4>
              </div>)
              }) }
            </div> */}

            <div className="mt-8 mb-6 flex w-full sm:w-2/5 justify-between lg:absolute bottom-1 text-accent font-primary">
              <div className="flex-col items-center justify-center">
                <div className="flex justify-center mb-1">
                  <ShieldCheck className="h-10 w-10 text-primary" />
                </div>
                <h4 className="text-[11px] text-center font-medium">
                  Certification
                </h4>
              </div>
              <div className="flex-col items-center justify-center">
                <div className="flex justify-center mb-1">
                  <img
                    src={level}
                    alt=""
                    height="2.2rem"
                    width="40px"
                    className="text-white text-center"
                  />
                </div>
                <h4 className="text-[11px] text-center font-medium">
                  Beginner
                </h4>
              </div>
              <div className="flex-col items-center justify-center">
                <div className="flex justify-center mb-1">
                  <Cloud className="h-10 w-10 text-primary" />
                </div>
                <h4 className="text-[11px] text-center font-medium">Online</h4>
              </div>
              <div className="flex-col items-center justify-center">
                <div className="flex justify-center mb-1">
                  <img
                    src={duration}
                    alt=""
                    height="2.2rem"
                    width="40px"
                    className="text-white text-center"
                  />
                </div>
                <h4 className="text-[11px] text-center font-medium">
                  8-10 weeks
                </h4>
              </div>
            </div>
          </div>

          {/* Price Card */}
          <div className="basis-2/5 text-white sm:border-2 rounded-2xl sm:border-primary font-primary">
            <div className="mt-3 sm:p-4 sm:mt-0">
              <h3 className="font-primary text-2xl pb-4">What you'll get:</h3>

              <ul className="font-primary pb-3 text-accent/75">
                <li>• Understanding Market Fundamentals</li>
                <li>• Market Trends and Candlesticks</li>
                <li>• Demand, Supply, Support, and Resistance</li>
                <li>• Trend Lines and Trading Strategies</li>
                <li>• Fibonacci Tools in Trading</li>
                <li>• Price Chart Patterns</li>
                <li>• Moving Averages in Trading</li>
                <li>• Risk Management</li>
                <li>• Stock Selection for Investment and Trading</li>
                <li>• Intraday and Long-Term Investment Strategies</li>
                <li>• Psychological Aspects of Trading</li>
                <li>• Escaping Trading Plateaus</li>
                <li>• Interactive Discussions and Q&A</li>
                <li>• Continuous Learning and Community Support</li>
              </ul>

              <div className="min-h-[1.4px] rounded-full mb-4 mt-2 bg-white/20"></div>

              <div className="flex flex-col sm:flex-row justify-center items-center">
                <h1 className="text-4xl mb-5 basis-3/5 w-full sm-max">
                  ₹14,999<span className="text-sm"> (one time only)</span>
                </h1>
                <div
                  className="text-sm mb-3 sm:mb-5  text-center basis-2/5 p-2 bg-white w-full sm:w-max text-bb rounded-xl hover:cursor-pointer hover:bg-white/95"
                  onClick={() => handleOpenModules()}
                >
                  <h3>View Modules</h3>
                </div>
              </div>
              <a href="https://rzp.io/l/tbemp" target="_blank">
              <div className="rounded-2xl px-5 py-4 bg-primary text-bb font-bold text-center hover:cursor-pointer  hover:bg-white transition-all duration-200    ">
                  Get Lifetime Access
              </div>
              </a>

            </div>
          </div>
        </div>
      </div>

      {/* Course 2 */}
      <div className="font-lekton text-white flex gap-4 pt-14 pb-8">
        <h1 className="flex items-end text-primary text-5xl md:text-7xl font-bold">
          02
        </h1>
        <h1 className="text-start text-2xl md:text-3xl">
          DERIVATIVES TRADING PROGRAM
        </h1>
      </div>

      <div className="gap-8 md:p-10 md:px-12 flex flex-col lg:flex-row">
        {/* <div className="h-52 flex-1 bg-blue-600"></div>
        <div className="h-52 flex-1 bg-red-600"></div> */}
        <div className="flex-1 sm:basis-3/5 relative order-first">
          <div>
            <img
              className="z-20 absolute mr-10 w-[95%] bg-blue-300 h-96 rounded-3xl object-cover"
              src={charts}
            ></img>

            <div className="lg:absolute min-h-96 bg-primary lg:w-[95%] right-3 top-6 rounded-3xl z-0"></div>
          </div>

          <div className="flex w-full sm:w-2/5 justify-between mt-8 mb-6 lg:absolute bottom-1 text-accent font-primary">
            <div className="flex-col items-center justify-center">
              <div className="flex justify-center mb-1">
                <ShieldCheck className="h-10 w-10 text-primary" />
              </div>
              <h4 className="text-[11px] text-center font-medium">
                Certification
              </h4>
            </div>
            <div className="flex-col items-center justify-center">
              <div className="flex justify-center mb-1">
                <img
                  src={level}
                  alt=""
                  height="2.2rem"
                  width="40px"
                  className="text-white text-center"
                />
              </div>
              <h4 className="text-[11px] text-center">Advanced</h4>
            </div>
            <div className="flex-col items-center justify-center">
              <div className="flex justify-center mb-1">
                <Cloud className="h-10 w-10 text-primary" />
              </div>
              <h4 className="text-[11px] text-center">Online</h4>
            </div>
            <div className="flex-col items-center justify-center">
              <div className="flex justify-center mb-1">
                <img
                  src={duration}
                  alt=""
                  height="2.2rem"
                  width="40px"
                  className="text-white text-center"
                />
              </div>
              <h4 className="text-[11px] text-center">10-12 weeks</h4>
            </div>
          </div>
        </div>

        <div className="flex-1 sm:basis-2/5 order-last text-white border-2 rounded-2xl border-primary font-primary">
          <div className="p-4">
            <h3 className="font-primary text-2xl pb-4">
              Become a professional!
            </h3>

            <ul className="font-primary pb-3 text-accent/75">
              <li>• Comprehensive Options Education</li>
              <li>• Option Greeks</li>
              <li>• Option Buying Strategies</li>
              <li>• Option Selling Strategies</li>
              <li>• Hedging Strategies</li>
              <li>• Future Strategies</li>
              <li>• Hedging Strategies in Bull and Bear Markets</li>
              <li>• Advanced Options and Future Strategies</li>
              <li>• Option Strategy Platforms</li>
              <li>• Market Psychology</li>
              <li>• Interactive Sessions and Q&A</li>
              <li>• Practical Application and Simulations</li>
              <li>• Continuous Support and Community</li>
            </ul>

            <div className="min-h-[1.4px] rounded-full mb-4 mt-2 bg-white/20"></div>

            <div className="flex flex-col sm:flex-row">
              <div className="basis-3/5 flex h-max">
                <h1 className="text-2xl mb-5 line-through">39,999</h1>
                <h1 className="text-4xl mb-5 ml-2">₹25,999</h1>
              </div>

              <div
                className="basis-2/5 text-sm mb-3 sm:mb-5 text-center h-max p-2 bg-white w-full sm:w-max text-bb rounded-xl hover:cursor-pointer hover:bg-white/95"
                onClick={() => handleOpenModules()}
              >
                <h3>View Modules</h3>
              </div>
            </div>

            <a href="https://rzp.io/l/cvsOFSL5yg" target="_blank">
            <div className="rounded-2xl px-5 py-4 bg-primary text-bb font-bold text-center hover:cursor-pointer hover:bg-white transition-all duration-200">
                Enroll Now!
            </div>
            </a>
          </div>
        </div>
      </div>

      <div></div>
    </div>
  );
}

export default CourseDescription;
