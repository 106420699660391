import React from "react";
import up from "../assets/trending-up.svg";
import stock1 from "../images/stock1.jpg";
import { motion } from "framer-motion";
import FeatureCard from "./FeatureCard";
import Features from "./Features";
import Memberships from "./Memberships";
import Slogans from "./Slogans";
import { useNavigate } from "react-router";
import YoutubeLink from "./YoutubeLink";


function Hero() {
  const navigate = useNavigate();

  return (
    <div className=" text-white min-h-screen font-lekton">
      <div className="relative h-screen ">
        <img
          src={stock1}
          alt=""
          className="absolute opacity-50 inset-0 h-full w-full object-cover [mask-image:_linear-gradient(to_right,transparent_0,_black_1000px,_black_calc(100%-960px),transparent_100%)]"
        />
        {/* <div className="absolute inset bg-gray-900 bg-opacity-50 z-50 h-full w-full"></div> */}
        <div className="asolute inset-0 flex flex-col ">
          <div className="h-96 flex items-end justify-center">
            <motion.p
              animate={{
                y: "-15px",
                opacity: 1,
              }}
              initial={{
                opacity: 0,
              }}
              transition={{
                duration: 0.8,
              }}
              className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center"
            >
              Invest with precision, 
              <br />
              thrive with vision.
              <br />
              <span className="font-primary text-sm sm:text-lg text-primary -mt-5">Your journey to financial excellence starts here.</span>
            </motion.p>
          </div>

          <div className="z-30 mt-5 w-full h-12 text-center flex items-center justify-center font-primary text-bold rounded-lg hover:cursor-pointer">
            <div className="px-3 py-2 bg-primary hover:bg-primary/90 transition-all duration-300 text-bb rounded-lg" onClick={() => navigate("/intro")}>
              <div className="flex gap-2">
                <h1   className="text-sm md:text-md font-bold">সহজে শিখুন</h1>
                <img src={up} alt="" height="2.5rem" width="21px" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className=" w-full h-16 bg-primary"></div> */}


      {/* Slogans */}
      <Slogans/>

      
      {/* Features */}
      <Features />


      {/* Memberships */}
      <Memberships />

      {/* Youtube Video Link */}
      <YoutubeLink title='SPECIFIC KNOWLEDGE TAILORED TO YOU'/>
    </div>
  );
}

export default Hero;
