import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router";

function HeroBengali({courseSection}) {

  const navigate = useNavigate()
  return (
    <div className="h-[40rem] flex flex-col items-center justify-center">
      <motion.p
        animate={{
          y: "-15px",
          opacity: 1,
        }}
        initial={{
          opacity: 0,
        }}
        transition={{
          duration: 0.8,
        }}
        className="font-bold text-xl sm:text-3xl md:text-4xl lg:text-5xl text-center leading-10"
      >
        স্টক মার্কেটে আপনার প্রয়াস,
        <br />
        আমাদের সহায়তা।{" "}
        {/* <span className="text-primary">(DISCIPLINED)</span> হওয়া দরকার। */}
      </motion.p>

      <div className="w-[85%] sm:w-[54%] md:w-[43%] mt-10 flex flex-col md:flex-row gap-3 px-8 pb-4">
        <div
        onClick={()=>{
          courseSection.current.scrollIntoView()
        }}
         className="basis-1/2 rounded-3xl px-5 py-4 border bg-accent text-bb font-bold text-center text-sm md:text-lg hover:cursor-pointer hover:bg-bb hover:text-accent transition-all duration-200">
        🔎 কোর্স অন্বেষণ
        </div>
        <a 
        href="tel:+919883028266" 
        className="basis-1/2 rounded-3xl px-5 py-4 bg-primary border-2 border-bb  text-bb font-bold text-center text-sm md:text-lg hover:cursor-pointer hover:bg-bb hover:text-accent transition-all duration-200">
        🤙 যোগাযোগ করুন
        </a>
      </div>
    </div>
  );
}

export default HeroBengali;
