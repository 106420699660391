import React from 'react'
import stocks from '../images/demat/dhan1.png'

function SingleDematLinkButton({name, image, url}) {
  return (
    <div className=' bg-accent text-bb rounded-xl flex gap-1 py-1 px-1 sm:px-1 sm:py-2 w-52 sm:w-44 hover:cursor-pointer'>
        <div className=''>
                <img src={image} alt=""className='h-16 w-50 object-cover' />
        </div>
        <div className='font-primary flex flex-col justify-center'> 
                <a className='text-md sm:text-xl pr-4 text-center'
                  href={url} target='_blank'
                >{name}</a>
        </div>
    </div>
  )
}

export default SingleDematLinkButton
