import React from 'react'
import { useRef, useEffect } from 'react'
import MembershipCard from './MembershipCard'
import {motion, useAnimation, useInView} from 'framer-motion'


function Memberships() {
  const freeTopicsArray = [
    { title: 'Unveiling the Stock Market' },
    { title: 'Deciphering Initial Public Offerings (IPOs)' },
    { title: 'Demystifying Demat Accounts' },
    { title: 'Insight into Depositories and NSDL/CDSL' },
    { title: 'Exploring Stock Market Indices' },
    { title: 'Technical Analysis vs. Fundamental Analysis' },
    { title: 'Understanding the Art of Investing' },
    { title: 'Initiating Your Trading/Investing Journey' },
    { title: 'Exploring Different Instruments for Investment' },
    { title: 'How to Invest in the Stock Market' },
    { title: 'Introduction to Mutual Funds and Other Investment Vehicles' },
  ];

  const equityTopicsArray = [
    { title: 'Understanding Market Fundamentals' },
    { title: 'Market Trends and Candlesticks' },
    { title: 'Demand, Supply, Support, and Resistance' },
    { title: 'Trend Lines and Trading Strategies' },
    { title: 'Fibonacci Tools in Trading' },
    { title: 'Price Chart Patterns' },
    { title: 'Moving Averages in Trading' },
    { title: 'Risk Management' },
    { title: 'Stock Selection for Investment and Trading' },
    { title: 'Intraday and Long-Term Investment Strategies' },
    { title: 'Psychological Aspects of Trading' },
    { title: 'Escaping Trading Plateaus' },
    { title: 'Interactive Discussions and Q&A' },
    { title: 'Continuous Learning and Community Support' }
  ];

  const derivativeTopicsArray = [
    { title: 'Comprehensive Options Education' },
    { title: 'Option Greeks' },
    { title: 'Option Buying Strategies' },
    { title: 'Option Selling Strategies' },
    { title: 'Hedging Strategies' },
    { title: 'Future Strategies' },
    { title: 'Hedging Strategies in Bull and Bear Markets' },
    { title: 'Advanced Options and Future Strategies' },
    { title: 'Option Strategy Platforms' },
    { title: 'Market Psychology' },
    { title: 'Interactive Sessions and Q&A' },
    { title: 'Practical Application and Simulations' },
    { title: 'Continuous Support and Community' }
  ];

  const ref = useRef(null)
  const isInView= useInView(ref, {once: true})

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.5,
        staggerChildren: 0.4
      }
    }
  };
  
  const item = {
    hidden: { x: 40, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1
    }
  };
  

  const mainControls = useAnimation()

  useEffect(()=>{
    if(isInView){
      mainControls.start("visible")
    }

  }, [isInView])
  
  
  return (
    <div ref={ref}>
      <div className='pb-20 w-[88%] xl:w-[1185px] m-auto'>
          <h1 className=' md:pt-24 text-2xl md:text-4xl font-primary text-center pb-12 md:pb-20 '>EXPLORE MEMBERSHIPS</h1>
          <motion.ul 
            variants={container}
            initial='hidden'
            animate={mainControls}
            className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 hover:cursor-pointer transition-all duration-500'
          >
            {[0,1,2].map((data,i)=>(
              <motion.li key={i} variants={item} data>
                <MembershipCard i = {i} title={i == 0 ? 'BASIC' : (i == 1) ? 'EQUITY' : 'DERIVATIVES'} 
                  price={i == 0 ? 'FREE' : (i == 1) ? '14,999' : '25,999'}
                  features={i == 0 ? freeTopicsArray : (i == 1 ? equityTopicsArray :derivativeTopicsArray)}
                  paymentUrl={i == 0 ? 'https://wa.link/z4dxkx' : (i == 1) ? 'https://wa.link/8fz2km' : 'https://wa.link/99dj5m'}
                />
              </motion.li>
            ))}
                  {/* <MembershipCard title='PRIME' price='7999' features={equityTopicsArray} variants={item}/>
                  <MembershipCard  title='PRO' price='9999' features={derivativeTopicsArray} variants={item}/>
                  <MembershipCard  title='MASTER' price='12,999'  features={derivativeTopicsArray} variants={item}/> */}
          </motion.ul>
      </div>
    </div>

  )
}

export default Memberships
