import React from "react";
import { useNavigate } from "react-router";
import logo from '../images/logo.png'
import { Link } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();

  return (
    <div>
      <div className="mt-20 pb-20  bg-kk text-white ">
        <div className="w-[78%]  m-auto pt-14 pb-10 sm:pb-20 flex gap-4">
          <img src={logo} alt="cbm" className="rounded-full h-8 sm:h-14" />
          <h1 className="sm:text-2xl font-primary text-primary flex justify-center items-center">Trading Beacon</h1>
        </div>

        <div className="grid grid-cols-2 gap-10 sm:grid-cols-4 justify-between w-[78%] m-auto">
          {/* Courses */}
          <div>
            <h2 className="text-bold font-primary text-xl mb-4">Courses</h2>
            <ul className="text-sm flex flex-col gap-2">
              <li>
                <a
                  href="#"
                  className="font-primary hover:text-primary transition-colors duration-200 ease-out"
                >
                  Features
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="font-primary hover:text-primary transition-colors duration-200 ease-out"
                >
                  Equity Trading Program
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="font-primary hover:text-primary transition-colors duration-200 ease-out"
                >
                  Derivatives Trading Program
                </a>
              </li>
            </ul>
          </div>
          {/* Company */}
          <div>
            <h2 className="text-bold font-primary text-xl mb-4">Company</h2>
            <ul className="text-sm flex flex-col gap-2">
              <li>
                <Link
                  to="/about"
                  className="font-primary hover:text-primary transition-colors duration-200 ease-out"
                >
                  About Us
                </Link>
              </li>
              <li>
                <a
                  href="#"
                  className="font-primary hover:text-primary transition-colors duration-200 ease-out"
                >
                  Contact Us
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="font-primary hover:text-primary transition-colors duration-200 ease-out"
                >
                  Support
                </a>
              </li>
            </ul>
          </div>
          {/* Social */}
          <div>
            <h2 className="text-bold font-primary text-xl mb-4">
              Social media
            </h2>
            <ul className="text-sm flex flex-col gap-2">
              <li>
                <a
                  href="https://www.instagram.com/tradingbeacon_/"
                  target="_blank"
                  className="font-primary hover:text-primary transition-colors duration-200 ease-out"
                >
                  Instagram
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/tradingbeacon"
                  target="_blank"
                  className="font-primary hover:text-primary transition-colors duration-200 ease-out"
                >
                  Facebook
                </a>
              </li>
              <li>
                <a
                  href="https://t.me/tradingbeaconbengali"
                  target="_blank"
                  className="font-primary hover:text-primary transition-colors duration-200 ease-out"
                >
                  Telegram
                </a>
              </li>
              <li>
                <a
                  href="https://chat.whatsapp.com/KRL49yQRxFL1J1L6t9hmIc"
                  target="_blank"
                  className="font-primary hover:text-primary transition-colors duration-200 ease-out"
                >
                  Whatsapp
                </a>
              </li>
            </ul>
          </div>
          {/* Policies */}
          <div>
            <h2 className="text-bold font-primary text-xl mb-4">Policies</h2>
            <ul className="text-sm flex flex-col gap-2">
              <li>
                <a
                  href="#"
                  className="font-primary hover:text-primary transition-colors duration-200 ease-out"
                >
                  T&C's
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="font-primary hover:text-primary transition-colors duration-200 ease-out"
                >
                  Privay
                </a>
              </li>
              <li>
                <a
                  onClick={() => navigate("/refund-policy")}
                  className="font-primary hover:cursor-pointer hover:text-primary transition-colors duration-200 ease-out"
                >
                  Refund
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
