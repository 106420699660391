import React from "react";

function SingleCourseBengali({ title, logo, tradingTopics, tradingPoints, i }) {
  return (
    <div className="h-full hover:cursor-pointer relative">
      <div className="basis-1/2 flex-grow min-h-32 h-full border-2 border-primary hover:border-accent rounded-3xl transition-all duration-200 flex flex-col justify-between ">
        <div>
          <img
            src={logo}
            alt=""
            className="w-full object-cover rounded-t-3xl h-52"
          />
          <div className="px-6 py-4 sm:px-8 sm:py-6 text-2xl font-bold text-primary">
            <h1>{title}</h1>
            {tradingPoints.map((data) => {
              return (
                <p className="text-sm mt-4 text-white font-normal leading-6">
                  {data.point}
                </p>
              );
            })}
          </div>

          <div className="rounded-full h-[1px] w-[90%] m-auto bg-gray-600"></div>

          <div>
            <h1 className="px-6 py-4 sm:px-8 sm:py-6 text-lg font-bold text-primary">
              কোর্স অন্তর্ভুক্ত বিষয়
            </h1>
          </div>

          <div className="">
            <div className="px-6 py-2 sm:px-8 sm:py-2 pt-1">
              {tradingTopics.map((data) => {
                return (
                  <div className="flex gap-3 leading-8">
                    <h1 className="text-primary">✔</h1>
                    <h1>{data.topic}</h1>
                  </div>
                );
              })}
            </div>
          </div>

        </div>

        <div>
            <div className="rounded-full h-[1px] w-[90%] m-auto bg-gray-600"></div>

            <div className="mt-2 flex gap-2 flex-col sm:flex-row md:gap-3 p-4">
              <a 
              href="tel:+919883028266"
              className="basis-1/2 rounded-3xl text-md sm:text-lg px-4 py-3 sm:px-5 sm:py-4 border bg-accent text-bb font-bold text-center hover:cursor-pointer hover:bg-bb hover:text-accent transition-all duration-200">
                Call Now!
              </a>
              <a 
              href={i == 0? 'https://rzp.io/l/tbemp' : 'https://rzp.io/l/cvsOFSL5yg'}
              target="_blank"
              className="basis-1/2 rounded-3xl text-md sm:text-lg px-4 py-3 sm:px-5 sm:py-4 bg-primary border-2 border-bb  text-bb font-bold text-center hover:cursor-pointer hover:bg-bb hover:text-accent hover:border-primary transition-all duration-200">
                Join Now
              </a>
            </div>
        </div>
      </div>
    </div>
  );
}

export default SingleCourseBengali;
