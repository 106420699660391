import React, { useEffect } from "react";
import {useNavigate} from 'react-router-dom'

function ConfirmationModal({ setOpenModal }) {
  const navigate = useNavigate()
  useEffect(() => {
    document.body.style.overflowY = "hidden";

    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, []);
  return (
    <div className="font-primary bg-opacity-5 z-50 h-screen backdrop-blur-lg flex justify-center items-center ">
      <div className="rounded-3xl bg-bb/50 h-max">
        <div className="bg-primary rounded-t-3xl text-bb text-2xl  font-bold px-8 py-4 flex justify-between items-center">
          <h1 className="text-lg sm:text-2xl">Successfully Subscribed!</h1>
          <button
            className=" text-sm font-lekton bg-bb/30 text-white right-0 top-0 px-4 py-1 rounded-full"
            onClick={() => setOpenModal(false)}
          >
            X
          </button>
        </div>

        <div className=" text-white font-primary px-8 py-6  w-[18rem] sm:w-[30rem] flex text-wrap h-max ">
          <p className="w-full font-lekton">
            Thank you for joining Trading Beacon! 🎉<br/> We're thrilled to have you
            onboard. Explore our courses, engage in discussions, and unlock your
            trading potential. Let's make your journey in the stock market
            rewarding!
          </p>
        </div>

        <div className="mt-4 flex flex-col md:flex-row gap-3 px-8 pb-4">
            <div className="basis-1/2 rounded-3xl px-5 py-4 border bg-accent text-bb font-bold text-center text-sm md:text-lg hover:cursor-pointer hover:bg-bb hover:text-accent transition-all duration-200"
            onClick={()=>{navigate('/courses')
            setOpenModal(false)}}>
              Explore Courses!
            </div>
            <div className="basis-1/2 rounded-3xl px-5 py-4 bg-primary border-2 border-bb  text-bb font-bold text-center text-sm md:text-lg hover:cursor-pointer hover:bg-bb hover:text-accent transition-all duration-200">
              Connect
            </div>
          </div>
      </div>
    </div>
  );
}

export default ConfirmationModal;
