import React from 'react'
import Marquee from 'react-fast-marquee'

import TestimonialCard from './TestimonialCard'

function Testimonials() {
  const mentorshipFeedback = [
    { 
      message: "Hlo saif sir I was so grateful to have you in my life as my mentor. The content was provided by you that was very helpful for me to increase my knowledge and confidence in the stock market. I was a loss making trader in starting but with your help and support I was able to make that loss in profit I build up so much confidence in my trading so thank you sir for being my support system",
      name: "Aarav",
      avatarUrl: "https://randomuser.me/api/portraits/men/70.jpg"
    },
    { 
      message: "Previously, I knew price action and many topics, but I was still failing to make profits. After joining your MENTORSHIP. I was aligned in a path where all the dots were connected and I was easily able to connect to every topic and implement them in every market condition. The MENTORSHIP helped me in molding my mindset as well as psychology and made trading simple and clear.",
      name: "Ishika",
      avatarUrl: "https://randomuser.me/api/portraits/women/71.jpg"
    },
    { 
      message: "The MENTORSHIP helped me in learning each and everything from basics to advance, clearing all the doubts. The best thing I felt was the support which I got from my mentor where my each and every doubt was cleared specifically and I got to know many things which were unclear to me regarding the market.",
      name: "Arjun",
      avatarUrl: "https://randomuser.me/api/portraits/men/72.jpg"
    },
    { 
      message: "In this mentorship, I came to know that the knowledge I was having from YouTube and the knowledge which was being provided in the MENTORSHIP. The topic was almost the same but the way I was mentored was totally different and made me amazed that trading is simple. just we need to build the mindset which I learned in this MENTORSHIP",
      name: "Ananya",
      avatarUrl: "https://randomuser.me/api/portraits/women/73.jpg"
    }
  ];
  
  console.log(mentorshipFeedback);
  
  
  return (
        <div className='md:mt-8 sm:mt-16 [mask-image:_linear-gradient(to_right,transparent_0,_black_40px,_black_calc(100%-45px),transparent_100%)]
        md:[mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)]'>
        <Marquee speed="35" className='mb-0 sm:mb-28 '>
        <div className='flex'>
                {mentorshipFeedback.map((data)=>
                   <TestimonialCard message={data.message} name={data.name} avatarUrl={data.avatarUrl}/>)}
        </div>

        </Marquee>
        </div>
  ) 
}

export default Testimonials
