import React from 'react'
import { useRef, useEffect } from 'react'
import FeatureCard from './FeatureCard'
import {motion, useAnimation, useInView} from 'framer-motion'
import dynamicLearning from '../images/feature-icons/dynamic_learning.svg'
import mindset from '../images/feature-icons/mindset.svg'
import wisdom from '../images/feature-icons/wisdom.svg'
import personalMembership from '../images/feature-icons/personal_mentorship.svg'


function Features() {
  const featuresArray = [
    {
      feature: "Tailored Wisdom",
      desc: "Embark on a personalized learning journey tailored to your trading aspirations. Our courses provide precise knowledge on strategies, analysis, and trends, empowering you to navigate the complex world of trading confidently and effectively.",
      logo: wisdom
    },
    {
      feature: "Simplified Mindset",
      desc: "Revolutionize your approach to trading education with our focus on cultivating a clear and purposeful mindset. Our straightforward courses empower you to navigate the intricacies of trading, fostering a mindset poised for strategic decision-making with confidence and precision.",
      logo: mindset
    },
    {
      feature: "Personal Mentorship",
      desc: "Catapult your trading journey with the invaluable support of dedicated mentors. Committed to delivering personalized guidance and industry insights, our mentors ensure you develop a strategic approach to trading, enhancing your skills and instilling confidence for success.",
      logo: personalMembership
    },
    {
      feature: "Dynamic Learning",
      desc: "Immerse yourself in a comprehensive learning experience integrating live market sessions and an extensive library of recorded content. Engage in real-time guidance, analysis, and interaction, complemented by the flexibility to revisit lessons and stay ahead of evolving market trends at your own pace.",
      logo:dynamicLearning
    }
  ];

  const ref = useRef(null)
  const isInView= useInView(ref, {once: true})

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.4,
        staggerChildren: 0.3
      }
    }
  };
  
  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };
  

  const mainControls = useAnimation()

  useEffect(()=>{
    if(isInView){
      mainControls.start("visible")
    }

  }, [isInView])

  return (
    <div className='w-[88%] xl:w-[1185px] m-auto' ref={ref}>
      <h1 className='mt-24 sm:mt-32 text-2xl md:text-4xl font-primary text-center'>DON’T JUST WATCH. LEARN.</h1>
      <motion.ul 
        className='grid grid-cols-1 md:grid-cols-2  m-auto gap-4 sm:gap-3 mb-20 mt-12 transition-all duration-500'
        variants={container}
        initial='hidden'
        animate={mainControls}
      >
        {featuresArray.map((data,i)=>(
          <motion.li key={i} variants={item} data>
                    <FeatureCard feature={data.feature} desc={data.desc} logo={data.logo}/>
          </motion.li>
        ))}
        {/* <FeatureCard/>
        <FeatureCard/>
        <FeatureCard/>
        <FeatureCard/> */}
      </motion.ul>
    </div>
  )
}

export default Features
