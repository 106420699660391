import React from 'react';
import check from '../assets/check.svg';
import { useNavigate } from 'react-router';

function MembershipCard({ i,title, price, features, paymentUrl }) {
  const navigate = useNavigate()
  return (
    <div className='flex flex-col h-full px-8 py-8 border-2 border-primary rounded-3xl hover:border-white transition-all duration-200 hover:scale-[98%]'>
      <h1 className='text-center text-xl md:text-3xl'>{title}</h1>
      {/* <h1 className='text-center text-2xl md:text-4xl font-primary text-primary pb-6 md:pb-10'>{`₹${price}/-`}</h1> */}
      <h1 className='text-center text-2xl md:text-4xl font-primary text-primary pb-6 md:pb-10'>
      <div className="flex flex-col justify-center">
              <h1 className="text-2xl line-through decoration-2 decoration-white">{i == 0 ? '₹4,999/-' : (i == 1) ? '₹25,999/-' : '₹39,999/-'}</h1>
              <h1 className="text-4xl">{i == 0 ? 'FREE' :`₹${price}/-`}</h1>
            </div>
      </h1>

      <div className='flex flex-col flex-grow'>
        <div className='flex flex-col gap-2 pb-8 justify-between'>
          {features.map((data) => (
            <div key={data.title} className='flex gap-4'>
              <img src={check} alt="" className='w-[18px] sm:w-[26px]' />
              <p className='font-lekton font-extralight text-gray-100 text-sm md:text-md'>{data.title}</p>
            </div>
          ))}
        </div>
        
        <div className='flex flex-col mt-auto'>
          <div 
          onClick={()=>navigate('/courses/modules')}
          className='font-primary border-primary border rounded-3xl text-center p-4 mb-2 text-sm md:text-md'>
            Learn More
          </div>
          <a 
          href={paymentUrl}
          target='_blank'
          className='font-primary bg-primary text-bb font-bold rounded-3xl text-center p-4 hover:bg-white transition-all duration-200 text-sm md:text-md'>
            {i == 0 ? 'GET FREE ACCESS' : 'JOIN NOW'}
          </a>
        </div>
      </div>
    </div>
  );
}

export default MembershipCard;
