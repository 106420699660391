import React from "react";
import SingleCourseModule from "./SingleCourseModule";
import { motion, useAnimation, useInView } from "framer-motion";
import { useRef, useEffect } from "react";

function CourseModules() {
  const derivativesTradingProgram = [
    {
      title: "Comprehensive Options Education",
      description: [
        "Thorough coverage of the basics of options trading, including call and put options, their characteristics, and how they function in financial markets.",
      ],
    },
    {
      title: "Option Greeks",
      description: [
        "In-depth exploration of option Greeks (Delta, Gamma, Theta, Vega, and Rho) and their significance in option pricing.",
        "How to use Greeks to make informed trading decisions.",
      ],
    },
    {
      title: "Option Buying Strategies",
      description: [
        "In-depth exploration of various option buying strategies, such as long calls, long puts, and combinations like straddles and strangles.",
        "Practical applications and examples for each strategy.",
      ],
    },
    {
      title: "Option Selling Strategies",
      description: [
        "Detailed understanding of option selling strategies for different market scenarios.",
        "Risk management techniques associated with option selling.",
      ],
    },
    {
      title: "Hedging Strategies",
      description: [
        "Exploration of different hedging strategies for different market scenarios using options to protect against adverse market movements.",
        "Practical examples of how to use options for portfolio hedging.",
      ],
    },
    {
      title: "Future Strategies",
      description: [
        "In-depth exploration of strategies involving trading futures contracts in different market scenarios.",
        "Understanding the risk and reward profiles associated with futures trading.",
        "Application of futures strategies in bullish, bearish, and neutral market conditions.",
      ],
    },
    {
      title: "Hedging Strategies in Bull and Bear Markets",
      description: [
        "Techniques for safeguarding investments in both bullish and bearish market trends using a variety of hedging strategies.",
        "Understanding the role of derivatives in risk mitigation during market fluctuations.",
        "Practical examples of hedging approaches to ensure safety and stability in portfolios.",
      ],
    },
    {
      title: "Advanced Options and Future Strategies",
      description: [
        "Introduction to advanced options and future trading strategies, such as iron condors, butterfly spreads, and ratio spreads.",
        "Real-world case studies illustrating the effectiveness of these advanced strategies.",
      ],
    },
    {
      title: "Option Strategy Platforms",
      description: [
        "Practical guidance on using option trading platforms, including platform walkthroughs and demonstrations.",
        "Tips and tricks for efficient execution and monitoring of option strategies.",
      ],
    },
    {
      title: "Market Psychology",
      description: [
        "Understanding the psychological aspects of trading and how market sentiment influences options and futures markets.",
        "Strategies for emotional discipline and decision-making under various market conditions.",
      ],
    },
    {
      title: "Interactive Sessions and Q&A",
      description: [
        "Live mentorship sessions with opportunities for participants to ask questions and seek clarification.",
        "Engaging discussions on current market events and trends.",
      ],
    },
    {
      title: "Practical Application and Simulations",
      description: [
        "Hands-on exercises and simulations to apply theoretical knowledge in a practical setting.",
        "Virtual trading scenarios to reinforce learning.",
      ],
    },
    {
      title: "Continuous Support and Community",
      description: [
        "Ongoing support through a community forum or group where participants can interact, share insights, and discuss strategies.",
        "Regular updates on market trends and potential opportunities.",
      ],
    },
  ];

  const equityTradingProgram = [
    {
      title: "Understanding Market Fundamentals",
      description: [
        "Overview of how the equity market works.",
        "Introduction to market participants, order types, and market structure.",
      ],
    },
    {
      title: "Market Trends and Candlesticks",
      description: [
        "Exploration of different types of market trends.",
        "Comprehensive study of candlestick charts, their formation, and characteristics.",
        "Identification and analysis of various candlestick patterns.",
      ],
    },
    {
      title: "Demand, Supply, Support, and Resistance",
      description: [
        "Understanding the principles of demand and supply in the market.",
        "Techniques for identifying and plotting support and resistance levels.",
      ],
    },
    {
      title: "Trend Lines and Trading Strategies",
      description: [
        "How to draw and utilize trend lines for effective trading.",
        "Practical strategies for trading based on trend lines.",
      ],
    },
    {
      title: "Fibonacci Tools in Trading",
      description: [
        "Understanding the role of Fibonacci retracement and extension tools.",
        "Application of Fibonacci levels in identifying potential reversal and extension points.",
      ],
    },
    {
      title: "Price Chart Patterns",
      description: [
        "Identification and analysis of different types of price chart patterns.",
        "Recognition of reversal and continuation patterns, including triangle patterns.",
      ],
    },
    {
      title: "Moving Averages in Trading",
      description: [
        "Understanding moving averages and their significance.",
        "Practical application of moving averages in trading strategies.",
      ],
    },
    {
      title: "Risk Management",
      description: [
        "Strategies for effective risk management in trading.",
        "Understanding position sizing and its importance.",
        "Calculating and maintaining a favorable risk-reward ratio.",
      ],
    },
    {
      title: "Stock Selection for Investment and Trading",
      description: [
        "Criteria for selecting stocks for long-term investment and trading.",
        "Techniques for identifying potential opportunities in the equity market.",
      ],
    },
    {
      title: "Intraday and Long-Term Investment Strategies",
      description: [
        "Differentiated approaches for intraday trading and long-term investment.",
        "Balancing short-term gains with long-term growth.",
      ],
    },
    {
      title: "Psychological Aspects of Trading",
      description: [
        "Building a strong trading mindset.",
        "Discipline in trading and avoiding emotional pitfalls.",
        "Dealing with FOMO (Fear of Missing Out) and revenge trading.",
      ],
    },
    {
      title: "Escaping Trading Plateaus",
      description: [
        "Strategies for breaking through trading plateaus.",
        "Identifying and overcoming challenges in trading.",
      ],
    },
    {
      title: "Interactive Discussions and Q&A",
      description: [
        "Live sessions for discussions and Q&A.",
        "Addressing specific queries and challenges faced by participants.",
      ],
    },
    {
      title: "Continuous Learning and Community Support",
      description: [
        "Regular updates on market trends and developments.",
        "Community forum for participants to share insights and experiences.",
      ],
    },
  ];

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { x: 140, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
    },
  };

  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  return (
    <div className="pb-6 pt-12 sm:pb-10 sm:pt-20" ref={ref}>
      <div className="w-[88%] lg:w-[1185px] m-auto">
        <h1 className="font-lekton text-primary text-3xl mb-8">
          EQUITY TRADING PROGRAM
        </h1>
        <motion.ul
                    variants={container}
                    initial="hidden"
                    animate={mainControls}>
            {equityTradingProgram.map((data, i) => (
              <motion.li key={i} variants={item} data>
                <SingleCourseModule singleModuleData={data} moduleNum={i} />
              </motion.li>
            ))}

        </motion.ul>

        <h1
        id="derivativesModules"
         className="font-lekton text-primary text-3xl mb-8 mt-20">
          DERIVATIVES TRADING PROGRAM
        </h1>
        {derivativesTradingProgram.map((data, i) => {
          return <SingleCourseModule singleModuleData={data} moduleNum={i} />;
        })}
      </div>
    </div>
  );
}

export default CourseModules;
