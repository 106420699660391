import React from 'react'
import saifpic from '../images/saif.png'
import Footer from '../components/Footer'
import Goals from '../components/Goals';
import Testimonials from '../components/Testimonials';
import Newsletter from '../components/Newsletter'

import vm from '../images/featured/verna_magazine.png'
import ani from '../images/featured/ANI_5911.jpg'
import bs from '../images/featured/business-standard-logo-2.png'
import ed from '../images/featured/ed_times.jpg'
import gs from '../images/featured/gobestats.jpg'
import tm from '../images/featured/times_bulletin.png'

function AboutUs({setOpenModal}) {
        const ourGoals = [
                { title: "Focused Knowledge for Skill Development" },
                { title: "Simplified Learning Content" },
                { title: "Empowering Your Skills" },
                { title: "Hand Holding Experience" },
                { title: "Holistic Approach to Trading" },
                { title: "Practical Application" },
        ];

              
  return (
   <div>
        <div className='w-[88%] xl:w-[1185px] m-auto   flex flex-col md:flex-row pt-8 sm:pt-16 transition-all duration-200'>
                <div className='basis-[55%] '>
                        <h2 className='text-3xl md:text-5xl text-primary font-primary'>Our Mission</h2>
                        <p className='mt-6 mb-4 text-accent font-3xl font-primary'>At Trading Beacon, our mission is to empower individuals like you with the knowledge, skills, and confidence needed to navigate the complexities of trading and investing successfully. We strive to create a transformative learning experience that goes beyond traditional approaches, offering a direct path to acquiring practical skills for real-world application.</p>
                        <h2 className='text-xl sm:text-3xl text-primary font-primary'>Our Founder</h2>
                        <p className='mt-3 mb-12 text-accent font-3xl font-primary'>Saif Rabbani is a seasoned trader/investor with over 5+ years of experience in both equity and derivative markets. With a knack for analyzing market trends and making informed decisions, he has successfully guided more than 900 clients across the nation. His expertise extends beyond trading; Saif is also a skilled mentor, adept at imparting practical trading knowledge to students. Starting his trading journey at a young age, Saif has honed his skills over the years and holds certifications of NISM. His success lies not just in his own trading endeavors but in empowering others to navigate the complexities of the financial markets effectively.</p>
                        <div className='grid grid-cols-2 grid-rows-3 gap-4 gap-y-4'>
                                {ourGoals.map(data=><Goals title={data.title}/>)}
                        </div>

                </div>
                <div className='basis-[45%] relative'>
                        <img src={saifpic} alt="" className='scale-[87%]'/>

                </div>
        </div>

        
        {/* Featured On */}
        <div className='w-[88%] xl:w-[1185px] m-auto rounded-3xl mt-14'>
                <h2 className='text-3xl text-white font-primary text-center mb-8'>Featured On</h2>
                <div className='flex flex-wrap justify-center gap-6 md:gap-20 items-center p-10 bg-white rounded-3xl'>
                        <img src={vm} alt="" className='w-16 h-16' />
                        <img src={bs} alt="" className='w-30 h-20' />
                        <img src={gs} alt="" className='w-16 h-16' />
                        <img src={tm} alt="" className='w-16 h-16' />
                        <img src={ani} alt="" className='w-16 h-16' />
                        <img src={ed} alt="" className='w-16 h-24' />
                </div>
                {/* <div className='flex justify-center gap-20 items-center'>
 
                </div> */}
        </div>


        <p 
          className='text-2xl md:text-5xl  text-white font-primary text-center mt-20 mb-8 sm:mb-16'>
          What People say
          </p>
        <Testimonials/>

        <Newsletter setOpenModal={setOpenModal}/>

  </div>

  )
}

export default AboutUs
